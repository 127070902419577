import EventCountdownTimer from "components/EventCountdownTimer";
import GalleryView from "components/Gallery";
import { Link } from "react-router-dom";
import "screens/Ministries/styles.scss";
import { JoinMinistry } from "..";

const WomenMinistry = () => {
  return (
    <div className="ministries">
      <section
        className="hero-wrap"
        style={{
          backgroundImage: `url('${require("../../../assets/gallery/Women/compressed/2.webp")}')`,
        }}
      >
        <div className="overlay"></div>
        <div className="container">
          <div className="row no-gutters slider-text justify-content-centers align-items-center">
            <div className="col-md-8 text-left">
              <p data-aos="fade-up" className="breadcrumbs mb-2">
                <span className="mr-2">
                  <Link to="/ministries">
                    Ministries <i className="fa fa-chevron-right"></i>
                  </Link>
                </span>{" "}
                <span>
                  Women Ministry <i className="fa fa-chevron-right"></i>
                </span>
              </p>
              <h1 data-aos="fade-up" className="mb-0 bread">
                Women Ministry
              </h1>
              <p data-aos="fade-up" className="sub-text">
                A gathering of inspiring women, empowering one another through
                shared experiences, fellowship, fostering deep connections
                relationships.
              </p>
            </div>
          </div>
        </div>
        <EventCountdownTimer />
      </section>

      <section className="ftco-section ministry-description">
        <div className="container">
          <div className="row"></div>
          <div className="row">
            <div className="col-md-6">
              <div className="heading-section mb-4">
                <h2>About Ministry</h2>
              </div>
              <p>
                Welcome to the Foursquare Women International (FWI) section of
                Foursquare Gospel Church VGC—a vibrant community of women
                dedicated to faith, fellowship, and service. At FWI, we
                celebrate the transformative power of God's love in the lives of
                women. Our mission is to foster spiritual growth, nurture
                relationships, and engage in meaningful service within and
                beyond our church community.
              </p>
              <p>
                Our commitment to prayer, evangelism, and serving in our diverse
                roles has not only strengthened our individual walks with God
                but has also contributed to the growth of our community.
              </p>
              <p>
                As part of our key deliverables, 'The Total Woman Conference'
                was a highlight, bringing together women for a powerful time of
                rejuvenation and reflection in the presence of the Lord. Led by
                Elder Mrs. Yemi Lemo, the conference is a testament to our
                dedication to spiritual enrichment. Our Tuesday prayer altar, a
                consistent source of spiritual empowerment, witnessed increased
                attendance, reflecting the hunger for communion and growth.
              </p>
              <p>
                Beyond planned activities, we engage in impactful evangelism,
                witnessing the transformation of lives through dedicated
                sessions. As we embark on a new church year, our focus remains
                on trusting and believing in God's leading for a deeper walk
                with Him.
              </p>
              <p>
                Join us at FWI, where women of faith come together to inspire,
                uplift, and empower one another in our collective journey of
                discipleship. Come be a part of our community as we seek to make
                a difference for Christ in our families, church, and the world.
              </p>
            </div>
            <div className="col-md-5 offset-md-1">
              <JoinMinistry
                info={{
                  skills: "",
                  social_media: [
                    {
                      icon: <i className="fa fa-twitter" />,
                      link: "/",
                    },
                    {
                      icon: <i className="fa fa-instagram" />,
                      link: "/",
                    },
                    {
                      icon: <i className="fa fa-link" />,
                      link: "/",
                    },
                  ],
                  contact: {
                    people: [
                      {
                        name: "Mrs. Kikelomo Lawal",
                        email: "fwi@foursquarevgc.org",
                      },
                    ],
                    note: null,
                  },
                }}
              />
            </div>
          </div>
        </div>
      </section>

      <GalleryView
        title="Women Pictures"
        folder="Women"
        numberOfPictures={24}
        imageFormat="webp"
      />
    </div>
  );
};

export default WomenMinistry;
