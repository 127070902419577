import EventCountdownTimer from "components/EventCountdownTimer";
import GalleryView from "components/Gallery";
import { Link } from "react-router-dom";
import "screens/Ministries/styles.scss";
import { JoinMinistry } from "..";

const HallOfMentorshipDepartment = () => {
  return (
    <div className="ministries">
      <section
        className="hero-wrap"
        style={{
          backgroundImage: `url(${require('../../../assets//images/mentor.jpeg')})`,
          backgroundPositionY: "bottom",
        }}
      >
        <div className="overlay"></div>
        <div className="container">
          <div className="row no-gutters slider-text justify-content-centers align-items-center">
            <div className="col-md-8 text-left">
              <p data-aos="fade-up" className="breadcrumbs mb-2">
                <span className="mr-2">
                  <Link to="/ministries">
                    Ministries <i className="fa fa-chevron-right"></i>
                  </Link>
                </span>{" "}
                <span>
                  Hall of Mentorship <i className="fa fa-chevron-right"></i>
                </span>
              </p>
              <h1 data-aos="fade-up" className="mb-0 bread">
                Hall of Mentorship Ministry
              </h1>
            </div>
          </div>
        </div>
        <EventCountdownTimer />
      </section>

      <section className="ftco-section ministry-description">
        <div className="container">
          <div className="row"></div>
          <div className="row">
            <div className="col-md-6">
              <div className="heading-section mb-4">
                <h2>About Ministry</h2>
              </div>
              <p>
                The objective of the Hall of Mentorship (HOM) is the total
                development (spiritual, ethical / moral, and personal) of the
                teens and youth within the guidelines articulated in the “Hall
                Book”. The participants admitted to the ‘Hall of Mentorship’ are
                members or attendees of the Foursquare Gospel Church, VGC.
              </p>
              <p>
                The Hall Tenders are a team comprised of members of the church,
                as appointed by the Church Council, responsible for coordinating
                the HOM. The Mentees are individuals who are willing to learn
                and develop their own skills / competencies for personal growth
                and to meet the challenges of the Christian life.
              </p>{" "}
              <p className="mb-2">
                <b>PERFORMANCE AGAINST KEY DELIVERABLES:</b>
              </p>{" "}
              <p className="mb-2">
                Our 2022/2023 key focus was to deliver on the 2022/2023 hall of
                mentorship annual intake cycle and improve on the follow-up
                system to enhance the value delivered by the HOM. Specifically,
                we planned to:
              </p>
              <ol>
                <li>
                  Review the performance of the previous year’s intake. This was
                  done successfully.
                </li>
                <li>
                  Execute mentor-mentee intake programme for the 2022/2023
                  cycle. We were unable to do so.
                </li>
                <li>
                  Hold a career and professional development session for teens
                  and youths in collaboration with the Teens and Youth churches,
                  however, a decision was taken to merge ours with the CFM’s
                  capacity building programme for teens and youth.
                </li>
                <li>
                  Onboard new Hall Tenders. We achieved this. The mentor-mentee
                  intake programme was postponed due to a decision to improve
                  its structure and format to better reflect the needs of the
                  church as well as improve its efficiency.
                  <p style={{ marginBottom: "10px" }}></p>
                  Under the new dispensation, the execution of the programme
                  will be devolved to the relevant arms of the church, namely
                  the men’s community (CFM), teens community (Teens Ministry)
                  and youth community (Youth Ministry). Hall tenders are already
                  in conversation with the communities to work how to achieve
                  the objective in which they will be actively involved in the
                  operations.
                </li>
              </ol>
              <p></p> <hr />
            </div>
            <div className="col-md-5 offset-md-1">
              <JoinMinistry
                info={{
                  skills:
                    "Mentoring, Relational Skills, Patience, Communication and Enthusiasm.",
                  social_media: [],
                  contact: {
                    people: [
                      {
                        name: "Brother Emeka Okonkwo",
                        email: "hallofmentorship@foursquarevgc.org",
                      },
                    ],
                    note: null,
                  },
                }}
              />
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default HallOfMentorshipDepartment;
