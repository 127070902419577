import EventCountdownTimer from "components/EventCountdownTimer";
import GalleryView from "components/Gallery";
import { Link } from "react-router-dom";
import "screens/Ministries/styles.scss";
import { JoinMinistry } from "..";

const TimCorpsDepartment = () => {
  return (
    <div className="ministries">
      <section
        className="hero-wrap"
        style={{
          backgroundImage: `url('${require("../../../assets/gallery/OtherDepartments/TimCorps.jpg")}')`,
          backgroundPositionY: "bottom",
        }}
      >
        <div className="overlay"></div>
        <div className="container">
          <div className="row no-gutters slider-text justify-content-centers align-items-center">
            <div className="col-md-8 text-left">
              <p data-aos="fade-up" className="breadcrumbs mb-2">
                <span className="mr-2">
                  <Link to="/ministries">
                    Ministries <i className="fa fa-chevron-right"></i>
                  </Link>
                </span>{" "}
                <span>
                  TimCorps <i className="fa fa-chevron-right"></i>
                </span>
              </p>
              <h1 data-aos="fade-up" className="mb-0 bread">
                TimCorps Ministry
              </h1>
            </div>
          </div>
        </div>
        <EventCountdownTimer />
      </section>

      <section className="ftco-section ministry-description">
        <div className="container">
          <div className="row"></div>
          <div className="row">
            <div className="col-md-6">
              <div className="heading-section mb-4">
                <h2>About Ministry</h2>
              </div>
              <p>
                The Comfort Centre is a Christian-based, counselee focused, and
                result-oriented private counselling service facility set-up and
                operated by the Foursquare Gospel Church, VGC. It provides free
                broad-based counselling services and is manned by professional,
                highly experienced volunteer counsellors.
              </p>
              <p>
                Our vision is “To help transform personal challenges into
                life-enhancing opportunities.” The scope of our counselling
                services covers areas such as marriage and family, guidance and
                career, rehabilitation, mental health, educational, relational,
                and online counselling services.
              </p>
              <p>
                The TimCorps Programme is focused on transforming the lives of
                young people (aged 13 – 25 years) living in the underserved
                communities around our local church. During the period under
                review, in addition to the Discipleship Programme, TimCorps
                progressed its mission in support of the educational development
                of its members with targeted intervention of the TimCorps
                Education Support Fund (TESF).</p><p> Through scholarships, tutorial
                classes, textbooks, external exams fees (WAEC/NECO/JAMB) and
                related support, TESF provided for the educational needs of an
                expanding group of members at numerous academic institutions. We
                are ever thankful for the positive impact being made in the
                lives of these beneficiaries because of these interventions.
              </p>

              <p>
                <b>Outreach:</b> The Christmas Programme held on December 29, 2022, was
                repurposed as a combined outreach / in-reach targeting both old
                members and new converts. The event was combined with the
                Graduation Ceremony of the second Cohort of eighteen (18) Junior
                Disciplers (JD) bringing us to a total of thirty-five (35) JDs.
                To God’s glory, we recorded a total of eighty-three (83) guests
                as opposed to the two hundred and fifty that we had targeted for
                the 2022/2023 December outreach. To God’s glory, seventeen (17)
                Decisions for Christ were recorded during
              </p>

              <p>
                <b>Spiritual / Discipleship:</b> The Discipleship Programme continued
                throughout the year catering to a total of three hundred and
                forty-three (343) members with an average weekly attendance of
                forty (40) members every Sunday. We continued to focus attention
                on our goal of increasing mature disciples to two hundred (200)
                teens. We planned to increase the number of our Junior
                Disciplers to fifty within the reporting period but were able to
                get up to thirty-five.
              </p>

              <p><b>EDUCATIONAL SUPPORT</b></p>
              <p>
                The TimCorps Education Support Fund (TESF) inaugurated in May
                2021, has made commendable impact in the lives of TimCorps
                members. The educational support is provided under a
                well-structured arrangement for effective management and is
                targeted to improve attendance and spiritual development of
                committed TimCorps members who have graduated into Junior
                Disciples class and serve as role models and assistant
                coordinators to drive future TimCorps expansion.
              </p>
              <hr />
            </div>
            <div className="col-md-5 offset-md-1">
              <JoinMinistry
                info={{
                  skills:
                    "Empathy, Leadership, Patience, Communication and Enthusiasm.",
                  social_media: [],
                  contact: {
                    people: [
                      {
                        name: "Dcn. Olumide Bajomo",
                        email: "timcorps@foursquarevgc.org",
                      },
                    ],
                    note: null,
                  },
                }}
              />
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default TimCorpsDepartment;
