import SeniorPastorImg from "../../assets/images/pastors/compressed/5.webp";
import { Swiper, SwiperSlide } from "swiper/react";
// import required modules
import { EffectFade, Pagination, Navigation, Autoplay } from "swiper";
// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import 'swiper/css/effect-fade';
import "./styles.scss";
import { Link } from "react-router-dom";
import { ministries } from "screens/Ministries";
import EventCountdownTimer, {
  generateDesiredStartEndDate,
} from "components/EventCountdownTimer";
import GalleryView from "components/Gallery";
import { AddToCalendarButton } from "add-to-calendar-button-react";
import { useState } from "react";
import JoinMinistryForm from "components/Forms/JoinMinistryForm";
import NewMemberForm from "components/Forms/NewMemberForm";
import PrayerRequestForm from "components/Forms/PrayerRequestForm";
import NewConvertForm from "components/Forms/NewConvertForm";

const socials = [
  {
    type: "instagram",
    icon: <i className="lni lni-instagram-fill"></i>,
    url: "https://www.instagram.com/foursquarevgc/?hl=en",
  },
  {
    type: "facebook",
    icon: <i className="lni lni-facebook-fill"></i>,
    url: "https://www.facebook.com/FoursquareGospelChurchVgc/",
  },
  {
    type: "youtube",
    icon: <i className="lni lni-youtube"></i>,
    url: "https://m.youtube.com/@FoursquareChurchVGCLagos/streams",
  },
  {
    type: "whatsapp",
    icon: <i className="fa-brands fa-whatsapp"></i>,
    url: "https://wa.me/message/MK7TQXBGOBAOM1",
  },
  {
    type: "tiktok",
    icon: <i className="fa-brands fa-tiktok"></i>,
    url: "https://www.tiktok.com/@foursquarevgc",
  },
  {
    type: "podcast",
    icon: <i className="fa-solid fa-podcast"></i>,
    url: "https://www.google.com/podcasts?feed=aHR0cHM6Ly9hbmNob3IuZm0vcy81MjFhZGVkYy9wb2RjYXN0L3Jzcw==",
  },
];

const services = [
  {
    name: "Worship Service",
    icon: <i className="fa-solid fa-church"></i>,
    details:
      "Experience God’s presence in an atmosphere of worship and fellowship with other believers.",
    schedule: "Sunday | 9:20am",
    // image: require("../../assets/images/compressed/15.webp"),
    image: require("../../assets/images/worship2.jpg"),
    // image: "https://harvestersng.org/wp-content/uploads/2022/04/tabs-b.jpg",
    location: "Foursquare VGC Church",
    ...generateDesiredStartEndDate(0, 9, 20, 12, 0),
  },
  {
    name: "Bible Study",
    icon: <i className="fa-solid fa-book-bible"></i>,
    details: "Explore God’s word and grow spiritually.",
    schedule: "Wednesday | 6:30pm",
    image: require("../../assets/images/compressed/16.webp"),
    // image:
    //   "https://images.pexels.com/photos/6860819/pexels-photo-6860819.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
    location: "Foursquare VGC Church",
    ...generateDesiredStartEndDate(3, 18, 30, 20, 0),
  },
  {
    name: "Prayer Meeting",
    icon: <i className="fa-solid fa-person-praying"></i>,
    details: "Experience the power of God through prayers.",
    schedule: "Friday | 6:30pm",
    // image: require("../../assets/images/compressed/14.webp"),
    image: require("../../assets/images/prayer2.jpg"),
    location: "Foursquare VGC Church",
    ...generateDesiredStartEndDate(5, 18, 30, 20, 0),
  },
];

const sliders = [
  {
    title: "Welcome Home!",
    background: require("../../assets/images/hero/compressed/6.webp"),
    description:
      " Grow in your faith, learn about God’s plan for your life and be made ready for the coming of our Lord Jesus Christ.",
    actions: (
      <>
        <Link
          to="https://m.youtube.com/@FoursquareChurchVGCLagos/streams"
          className="btn btn-main px-md-4 mr-4"
          data-aos="fade-up"
        >
          <div className="content">
            <i className="fa-solid fa-circle-play"></i>
            Watch Service Online
          </div>
        </Link>
      </>
    ),
  },
  {
    title: "Welcome Home!",
    background: require("../../assets/images/hero/compressed/12.webp"),
    description:
      " Grow in your faith, learn about God’s plan for your life and be made ready for the coming of our Lord Jesus Christ.",
    actions: (
      <>
        <Link
          to="https://m.youtube.com/@FoursquareChurchVGCLagos/streams"
          className="btn btn-main px-md-4 mr-4"
          data-aos="fade-up"
        >
          <div className="content">
            <i className="fa-solid fa-circle-play"></i>
            Watch Service Online
          </div>
        </Link>
      </>
    ),
  },
  {
    title: "Welcome Home!",
    background: require("../../assets/images/hero/compressed/10.webp"),
    description:
      " Grow in your faith, learn about God’s plan for your life and be made ready for the coming of our Lord Jesus Christ.",
    actions: (
      <>
        <Link
          to="https://m.youtube.com/@FoursquareChurchVGCLagos/streams"
          className="btn btn-main px-md-4 mr-4"
          data-aos="fade-up"
        >
          <div className="content">
            <i className="fa-solid fa-circle-play"></i>
            Watch Service Online
          </div>
        </Link>
      </>
    ),
  },
  {
    title: "Welcome Home!",
    background: require("../../assets/images/hero/compressed/4.webp"),
    description:
      " Grow in your faith, learn about God’s plan for your life and be made ready for the coming of our Lord Jesus Christ.",
    actions: (
      <>
        <Link
          to="https://m.youtube.com/@FoursquareChurchVGCLagos/streams"
          className="btn btn-main px-md-4 mr-4"
          data-aos="fade-up"
        >
          <div className="content">
            <i className="fa-solid fa-circle-play"></i>
            Watch Service Online
          </div>
        </Link>
      </>
    ),
  },
  {
    title: "Welcome Home!",
    background: require("../../assets/images/hero/compressed/5.webp"),
    description:
      " Grow in your faith, learn about God’s plan for your life and be made ready for the coming of our Lord Jesus Christ.",
    actions: (
      <>
        <Link
          to="https://m.youtube.com/@FoursquareChurchVGCLagos/streams"
          className="btn btn-main px-md-4 mr-4"
          data-aos="fade-up"
        >
          <div className="content">
            <i className="fa-solid fa-circle-play"></i>
            Watch Service Online
          </div>
        </Link>
      </>
    ),
  },
  {
    title: "Welcome Home!",
    background: require("../../assets/images/hero/compressed/11.webp"),
    description:
      " Grow in your faith, learn about God’s plan for your life and be made ready for the coming of our Lord Jesus Christ.",
    actions: (
      <>
        <Link
          to="https://m.youtube.com/@FoursquareChurchVGCLagos/streams"
          className="btn btn-main px-md-4 mr-4"
          data-aos="fade-up"
        >
          <div className="content">
            <i className="fa-solid fa-circle-play"></i>
            Watch Service Online
          </div>
        </Link>
      </>
    ),
  },
  {
    title: "Welcome Home!",
    background: require("../../assets/images/hero/compressed/1.webp"),
    description:
      " Grow in your faith, learn about God’s plan for your life and be made ready for the coming of our Lord Jesus Christ.",
    actions: (
      <>
        <Link
          to="https://m.youtube.com/@FoursquareChurchVGCLagos/streams"
          className="btn btn-main px-md-4 mr-4"
          data-aos="fade-up"
        >
          <div className="content">
            <i className="fa-solid fa-circle-play"></i>
            Watch Service Online
          </div>
        </Link>
      </>
    ),
  },
  {
    title: "Welcome Home!",
    background: require("../../assets/images/hero/compressed/13.webp"),
    description:
      " Grow in your faith, learn about God’s plan for your life and be made ready for the coming of our Lord Jesus Christ.",
    actions: (
      <>
        <Link
          to="https://m.youtube.com/@FoursquareChurchVGCLagos/streams"
          className="btn btn-main px-md-4 mr-4"
          data-aos="fade-up"
        >
          <div className="content">
            <i className="fa-solid fa-circle-play"></i>
            Watch Service Online
          </div>
        </Link>
      </>
    ),
  },
];

const Home = () => {
  const [volunteerModalVisibility, setVolunteerModalVisibility] = useState(false);
  const [newMemberModalVisibility, setNewMemberModalVisibility] = useState(false);
  const [newConvertModalVisibility, setNewConvertModalVisibility] = useState(false);
  const [prayerRequestModalVisibility, setPrayerRequestModalVisibility] = useState(false);
  return (
    <div className="home">
      <JoinMinistryForm
        buttonColor="black"
        isVisible={volunteerModalVisibility}
        closeModal={() => setVolunteerModalVisibility(false)}
      />
      <NewMemberForm
        buttonColor="black"
        isVisible={newMemberModalVisibility}
        closeModal={() => setNewMemberModalVisibility(false)}
      />
      <NewConvertForm
        buttonColor="black"
        isVisible={newConvertModalVisibility}
        closeModal={() => setNewConvertModalVisibility(false)}
      />
      <PrayerRequestForm
        buttonColor="black"
        isVisible={prayerRequestModalVisibility}
        closeModal={() => setPrayerRequestModalVisibility(false)}
      />
      <section className="hero-wrap">
        <Swiper
          slidesPerView={1}
          spaceBetween={0}
          effect={'fade'}
          navigation={true}
          autoplay={{
            delay: 5000,
            disableOnInteraction: false,
          }}
          loop={true}
          pagination={{
            clickable: true,
          }}
          modules={[EffectFade, Autoplay, Navigation]}
          className="mySwiper"
        >
          {sliders.map(({ title, description, actions, background }, index) => {
            return (
              <SwiperSlide
                className="home-slider owl-carousel"
                style={{
                  background: `url(${background})`,
                }}
                key={index}
              >
                <div className="slider-item">
                  <div className="overlay"></div>
                  <div className="container">
                    <div className="row no-gutters slider-text align-items-center justify-content-center">
                      <div className="col-md-7 ftco-animate2">
                        <div className="text mt-md-5 w-100 text-center">
                          <h1 className="mb-3 welcome-text" data-aos="fade-up">
                            {title}
                          </h1>
                          <p className="mb-1 pb-3 sub-text" data-aos="fade-up">
                            {description}
                          </p>
                          <p className="mb-4 pb-3 sub-text" data-aos="fade-up"><b><i>Sundays: In-person and Online at 9:20am</i></b></p>
                          {actions}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </SwiperSlide>
            );
          })}
        </Swiper>
        <EventCountdownTimer />
      </section>

      <section className="ftco-section senior-pastor">
        <div className="container">
          <div className="row align-items-center justify-content-center">
            <div className="col-md-4">
              <div className="img-frame" data-aos="fade-right">
                <img
                  src={SeniorPastorImg}
                  alt="senior pastor"
                  className="img-fluid"
                />
              </div>
            </div>
            
            <div className="col-md-5 offset-md-1">
              <div className="heading-section mb-4" data-aos="fade-left">
                <span className="subheading">
                  Senior Pastor's Welcome Message
                </span>
                <h2 className="mb-3">
                  Welcome to Foursquare Gospel Church, VGC.
                </h2>
                <p>Dear friend,</p>

                <p>Thank you for visiting our website and welcome to the Foursquare Gospel Church, VGC.</p>

                <p>We are a community church committed to sharing God’s undiluted word in fulfilment of the Great Commission mandate in Matthew 28:19. Our services and programmes are focused on supporting the spiritual growth of our members and worshippers.</p>

                <p>Whether you are seeking spiritual fulfilment, new to the faith or a seasoned believer, there is a place for you here in Foursquare VGC. We also intentionally cater to the needs of children, teenagers, and youths, as we have robust programming and structures for these different groups.</p>

                <p>Please, explore our website, other online channels, and social media handles and platforms, as you’ll find much in there to engage you and help you to grow in your walk with Christ.</p>

                <p>I also invite you to join us, in person or virtually, for our Sunday services and weekly programmes. We look forward to fellowshipping with you in person or virtually.</p>

                <p>God bless you!</p>
                <p>
                  <span>
                  - Rev. Peter Ameadaji
                  </span>
                </p>
                <Link to="/about">
                  <button className="btn-main mt-4">Learn more about us</button>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="ftco-sections services2">
        <div className="container-fluid">
          <div className="row">
            {services.map((event, index) => {
              const {
                name,
                location,
                details,
                schedule,
                image,
                startDate,
                startTime,
                endDate,
                endTime,
              } = event || "";
              return (
                <div className="col-md-4 d-flex services-item" key={index}>
                  <div
                    className={`content-container item-${index}`}
                    data-aos="fade-up"
                    data-aos-delay={index * 100}
                    style={{ backgroundImage: `url('${image}')` }}
                  >
                    <div className={`overlays item-${index}`} />
                    <div className="content">
                      <h6>{name}</h6>
                      <p className="schedule">{schedule}</p>
                      <AddToCalendarButton
                        name={name}
                        description={details}
                        options={["Google"]}
                        location={location}
                        startDate={startDate}
                        endDate={endDate}
                        startTime={startTime}
                        endTime={endTime}
                        timeZone="Africa/Lagos"
                        trigger="click"
                        hideBackground={true}
                        hideCheckmark={true}
                        buttonStyle="custom"
                        customCss="css/button.css"
                      />
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </section>

      {/* <section className="ftco-section services">
        <div className="container-fluid">
          <div className="row justify-content-between align-items-end heading-section">
            <div className="col-md-9 text-left">
              <span className="subheading" data-aos="fade-up">
                DON’T MISS THESE
              </span>
              <h2 data-aos="fade-up">Our Service Times</h2>
            </div>
            <div className="col-md-3 text-md-right">
              <p data-aos="fade-up">
                <Link to="/events"> View All Events</Link>
              </p>
            </div>
          </div>
          <div className="row">
            {services.map((event, index) => {
              const {
                name,
                location,
                details,
                schedule,
                image,
                startDate,
                startTime,
                endDate,
                endTime,
              } = event || "";
              return (
                <div className="col-md-4 d-flex" key={index}>
                  <div
                    className={`services-item item-${index}`}
                    data-aos="fade-up"
                    data-aos-delay={index * 100}
                    style={{ backgroundImage: `url('${image}')` }}
                  >
                    <div className={`overlays item-${index}`} />
                    <div className="content">
                      <img src={image} alt="sa" className="img-fluid" />
                      <h6>{name}</h6>
                      <p className="schedule mb-2">{schedule}</p>
                      <AddToCalendarButton
                        name={name}
                        description={details}
                        options={["Google"]}
                        location={location}
                        startDate={startDate}
                        endDate={endDate}
                        startTime={startTime}
                        endTime={endTime}
                        timeZone="Africa/Lagos"
                        trigger="click"
                        hideBackground={true}
                        hideCheckmark={true}
                        buttonStyle="custom"
                        customCss="css/button.css"
                      />
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </section> */}

      <section className="ftco-section connecting">
        <div className="container">
          <div className="row justify-content-center align-items-centers heading-section">
            <div className="col-md-8 text-center">
              <span data-aos="fade-up" className="subheading">
                New to Foursquare?
              </span>
              <h2 data-aos="fade-up">Let's get you started quickly</h2>
            </div>
            <div className="col-md-12">
              <div className="row">
                <div className="col-md-4 d-flex">
                  <div className="connecting-item" data-aos="fade-up">
                    <div className="d-flex align-items-center mb-3">
                      <i className="fa-solid fa-user-plus mr-3"></i>
                      <h4>New Member?</h4>
                    </div>
                    <p>
                      Welcome to our church community! Join us to experience the
                      love, support, and guidance of a faith-based community.
                    </p>
                    <button onClick={() => setNewMemberModalVisibility(true)}
                    >
                      Join our church
                      <i className="lni lni-arrow-right"></i>
                    </button>
                  </div>
                </div>
                <div className="col-md-4 d-flex">
                  <div className="connecting-item" data-aos="fade-up">
                    <div className="d-flex align-items-center mb-3">
                      <i className="fa-solid fa-person-praying mr-3"></i>
                      <h4>Need prayer?</h4>
                    </div>
                    <p>
                      Submit your prayer requests and our community will pray
                      for you. You don't have to face your challenges alone.
                    </p>
                    <button onClick={() => setPrayerRequestModalVisibility(true)}>
                      Request prayer <i className="lni lni-arrow-right"></i>
                    </button>
                  </div>
                </div>
                <div className="col-md-4 d-flex">
                  <div className="connecting-item" data-aos="fade-up">
                    <div className="d-flex align-items-center mb-3">
                      <i className="fa-solid fa-handshake-angle mr-3"></i>
                      <h4>Want to volunteer?</h4>
                    </div>
                    <p>
                      We are looking for dedicated volunteers to join our team!
                      Make a difference in the lives of young people and gain
                      valuable experience.
                    </p>
                    <button onClick={() => setVolunteerModalVisibility(true)}>
                      Join a team <i className="lni lni-arrow-right"></i>
                    </button>
                  </div>
                </div>
                <div className="col-md-4 d-flex">
                  <div className="connecting-item" data-aos="fade-up">
                    <div className="d-flex align-items-center mb-3">
                      <i className="fa-solid fa-gift mr-3"></i>
                      <h4>Want to give to us?</h4>
                    </div>
                    <p>
                      Your generosity can help make a positive impact in our
                      community. Please consider donating to us and help us
                      continue our mission.
                    </p>
                    <Link to="/give">
                      Give to church <i className="lni lni-arrow-right"></i>
                    </Link>
                  </div>
                </div>
                <div className="col-md-4 d-flex">
                  <div className="connecting-item" data-aos="fade-up">
                    <div className="d-flex align-items-center mb-3">
                      <i className="fa-solid fa-podcast mr-3"></i>
                      <h4>Want to listen to sermons?</h4>
                    </div>
                    <p>
                      Missed a sunday service or bible study sermon? Not to
                      worry, you can catch up on all our past sermons here.
                    </p>
                    <Link to="/sermons">
                      Listen to sermons <i className="lni lni-arrow-right"></i>
                    </Link>
                  </div>
                </div>
                <div className="col-md-4 d-flex">
                  <div className="connecting-item" data-aos="fade-up">
                    <div className="d-flex align-items-center mb-3">
                      <i className="fa-solid fa-cross mr-3"></i>
                      <h4>New Convert?</h4>
                    </div>
                    <p>
                      Did you just accept the life of Christ recently? Not to 
                      worry, we'll help you grow and be planted in the Lord better?
                    </p>
                    <button onClick={() => setNewConvertModalVisibility(true)}>
                      Contact Us <i className="lni lni-arrow-right"></i>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="ftco-sections ministries">
        <div className="container">
          <div className="row justify-content-center pb-5 mb-3">
            <div className="col-md-6 heading-section text-center ftco-animate2">
              <span data-aos="fade-up" className="subheading">
                Explore Ministries
              </span>
              <h2 data-aos="fade-up">
                Join a ministry and impact lives as Jesus did while on earth
              </h2>
            </div>
          </div>
        </div>
        <div className="container">
          <div className="row">
            {ministries.slice(0, 4).map((event, index) => {
              const { title, img, link } = event || "";
              return (
                <div className="col-md-3 d-flex ministry-item" key={index}>
                  <Link
                    to={link}
                    className={`content-container item-${index}`}
                    data-aos="fade-up"
                    data-aos-delay={index * 100}
                    style={{ backgroundImage: `url('${img}')` }}
                  >
                    <div className={`overlays item-${index}`} />
                    <div className="content">
                      <h6>{title}</h6>
                    </div>
                  </Link>
                </div>
              );
            })}
          </div>
        </div>
        <div className="col-md-12 text-center mt-4">
          <a href="/ministries">
            <button
              data-aos="fade-in"
              className="btn-main aos-init aos-animate"
            >
              Explore all ministries
            </button>
          </a>
        </div>
      </section>

      <section className="ftco-section socials">
        <div className="container">
          <div className="row justify-content-center mb-4">
            <div className="col-md-7 heading-section text-center ftco-animate2">
              <span data-aos="fade-up" className="subheading">
                SOCIAL MEDIA
              </span>
              <h2 data-aos="fade-up">Connect with us on social media</h2>
            </div>
          </div>
          <div className="row justify-content-center">
            <div className="col-md-8">
              <div className="row d-flex justify-content-center">
                {socials.map(({ icon, type, url }, index) => (
                  <div className="col-md-6 col-lg-3" key={index}>
                    <a href={url} rel="noreferrer" target="_blank">
                      <div
                        className={`social-media-item icon-${type}`}
                        data-aos="zoom-in-up"
                      >
                        {icon}
                        <p>{type}</p>
                      </div>
                    </a>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="ftco-section testimony-section">
        <div className="overlay"></div>
        <div className="container">
          <div className="row justify-content-center pb-5">
            <div className="col-md-7 heading-section heading-section-white text-center ftco-animate2">
              <span data-aos="fade-up" className="subheading">
                Testimonies
              </span>
              <h2 data-aos="fade-up">
                Read the life inspiring encounters of our members.
              </h2>
            </div>
          </div>
          <div className="row ftco-animate2 justify-content-center">
            <div className="col-md-12">
              <div className="carousel-testimony" data-aos="fade-up">
                <Swiper
                  slidesPerView={1}
                  spaceBetween={20}
                  centeredSlides={true}
                  loop={true}
                  navigation={true}
                  pagination={{
                    clickable: true,
                  }}
                  breakpoints={{
                    768: {
                      slidesPerView: 2,
                      spaceBetween: 60,
                    },
                  }}
                  modules={[Navigation, Pagination]}
                  className="mySwiper"
                >
                  {testimonies.map(
                    (item, index) => (
                      <SwiperSlide className="item" key={index}>
                        <div className="testimony-wrap d-md-flex">
                          <div className="text">
                            <span className="quote d-flex align-items-center justify-content-center">
                              <i className="fa fa-quote-left"></i>
                            </span>
                            <p>
                              {item}
                            </p>
                          </div>
                        </div>
                      </SwiperSlide>
                    )
                  )}
                </Swiper>
              </div>
            </div>
          </div>
        </div>
      </section>

      <GalleryView title="Our Pictures" folder="Home" numberOfPictures={8} imageFormat="webp" />
    </div>
  );
};

export default Home;


const testimonies = [
  "A brother testified to the glory of God of how he shared the Gospel at his family gathering, and the head of his family openly embraced the salvation offered through the Cross and gave his life to the Lord Jesus.",
  "An online worshipper thanked the Lord for the impact our church has had on his walk with the Lord. He said our church brings him closer and closer to God Almighty and he is grateful to God for connecting him to Foursquare VGC.",
  "A sister thanked the Lord for making her daughter a positive influ ence amongst her peers in school. She took her bible to school to have her devotion during lunchtime. The teacher noticed that there has been a positive change amongst the students as they now spend their lunchtime studying the bible.",
  "A youth in the diaspora thanked the Lord for baptising her with the Holy Spirit with the evidence of speaking in tongues.",
  "A sister also thanked the Lord for answering the prayers raised during the VPR for an entire family that was kidnapped. The Lord intervened and the family was released.",
  "Testimony of answered prayers from a first timer that worshipped with us. She said God answered the prayers she wrote on her first timers' card to His glory",
  "A brother testified of God's healing for his daughter that was diagnosed of COVID abroad. God healed her completely.",
  "A brother testified that after the youth prayer session on October 23rd, 2023, he noticed a scheduled meeting with their boss at 9 p.m., and as a result, their salaries were increased by 50 percent."
]