import EventCountdownTimer from "components/EventCountdownTimer";
import { Tabs } from "antd";
import type { TabsProps } from "antd";
import "./styles.scss";
import Gallery from "./components/Gallery";

const onChange = (key: string) => {
  console.log(key);
};

const items: TabsProps["items"] = [
  {
    key: "1",
    label: `Gallery`,
    children: <Gallery />,
  },
  {
    key: "2",
    label: `Dove`,
    children: `Content of Tab Pane 2`,
  },
  {
    key: "3",
    label: `Blog`,
    children: `Content of Tab Pane 3`,
  },
  {
    key: "4",
    label: `FAQ Page`,
    children: `Content of Tab Pane 3`,
  },
  {
    key: "5",
    label: `Downloads`,
    children: `Content of Tab Pane 3`,
  },
];

const events = [
  {
    title: "Food Distribution Ministry",
    description: "Provisions Ministry food and diaper distribution.",
    venue: "Foursquare gospel church",
    date: "31 July, 2021",
    image:
      "https://images.pexels.com/photos/6646989/pexels-photo-6646989.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2",
  },
  {
    title: "Writers Ministry",
    description:
      "Writers in his vineyard: A purposeful way to writing for the Lord",
    venue: "Foursquare gospel church",
    date: "31 July, 2021",
    image:
      "https://images.pexels.com/photos/1766604/pexels-photo-1766604.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2",
  },
  {
    title: "Marriage Ministry",
    description: "Virtual marriage prep with Pastor Okien Peters",
    venue: "Foursquare gospel church",
    date: "31 July, 2021",
    image:
      "https://images.pexels.com/photos/2253842/pexels-photo-2253842.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2",
  },
  {
    title: "Community Events",
    description:
      "Writers in his vineyard: A purposeful way to writing for the Lord",
    venue: "Foursquare gospel church",
    date: "31 July, 2021",
    image:
      "https://images.pexels.com/photos/3856033/pexels-photo-3856033.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2",
  },
  {
    title: "Writers Ministry",
    description: "Virtual marriage prep with Pastor Okien Peters",
    venue: "Foursquare gospel church",
    date: "31 July, 2021",
    image:
      "https://images.pexels.com/photos/1766604/pexels-photo-1766604.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2",
  },
  {
    title: "Community Events",
    description:
      "Writers in his vineyard: A purposeful way to writing for the Lord",
    venue: "Foursquare gospel church",
    date: "31 July, 2021",
    image:
      "https://images.pexels.com/photos/3856033/pexels-photo-3856033.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2",
  },
  {
    title: "Food Distribution Events",
    description: "Provisions Ministry food and diaper distribution.",
    venue: "Foursquare gospel church",
    date: "31 July, 2021",
    image:
      "https://images.pexels.com/photos/6646989/pexels-photo-6646989.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2",
  },
];

const Resources = () => {
  return (
    <div className="events">
      <section
        className="hero-wrap"
      >
        <div className="overlay"></div>
        <div className="container">
          <div className="row no-gutters slider-text justify-content-center align-items-center">
            <div className="col-md-6">
              <h1 className="mb-0 bread">Resources</h1>
              <p className="sub-text">
                Stay and renew your strength in the Lord with these regularly
                updated resources made for you.
              </p>
            </div>
          </div>
        </div>
        <EventCountdownTimer />
      </section>

      <section className="ftco-section upcoming-event">
        <div className="container">
          <div className="row justify-content-center pb-5 mb-3">
            <div className="col-md-12">
              <Tabs
                defaultActiveKey="1"
                centered
                items={items}
                onChange={onChange}
              />
            </div>
          </div>
          <div className="row">
            {events.map(({ title, description, venue, date, image }, index) => (
              <div className="col-md-3 d-md-flex ftco-animated">
                <div className="event-item">
                  <img src={image} alt="events" className="img-fluid" />
                  <div className="text pt-4">
                    <h5 className="mb-2">
                      <a href="sermons.html">{title}</a>
                    </h5>
                    <p>{description}</p>
                    <p className="mb-1">
                      <i className="fa fa-map-marker mr-2" /> {venue}
                    </p>
                    <p>
                      <i className="fa fa-calendar mr-2" /> {date}
                    </p>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>
    </div>
  );
};

export default Resources;
