import { PhotoProvider, PhotoView } from "react-photo-view";
import "react-photo-view/dist/react-photo-view.css";

const GalleryView = ({
  title,
  folder,
  numberOfPictures,
  imageFormat = "webp",
  isEmpty = false,
}: any) => {
  const pictures = Array.from(Array(numberOfPictures).keys());
  return (
    <section className="ftco-section gallery-section ftco-no-pb ftco-no-pts">
      <div className="container-fluid px-md-0">
        <div className="row no-gutters justify-content-center pb-5 mb-3">
          <div className="col-md-7 heading-section text-center ftco-animate2">
            <span className="subheading">Gallery</span>
            <h2>{title}</h2>
          </div>
        </div>
        <PhotoProvider>
          <div
            className="row no-gutters justify-content-center"
            style={{ padding: "4px" }}
          >
            {isEmpty ? (
              <div
                className="col-md-10 text-center"
                style={{ margin: "30px 0px 120px" }}
              >
                {" "}
                <i className="fa-solid fa-ban" /> No images here yet
              </div>
            ) : (
              pictures.map((item, index) => (
                <div className="col-md-3 p-1" key={index}>
                  <PhotoView
                    key={title + index}
                    src={require(`../../assets/gallery/${folder}/compressed/${
                      item + 1
                    }.${imageFormat}`)}
                  >
                    <img
                      className="image-popup img gallery"
                      src={require(`../../assets/gallery/${folder}/compressed/${
                        item + 1
                      }.${imageFormat}`)}
                      alt="dele"
                    />
                  </PhotoView>
                </div>
              ))
            )}
          </div>
        </PhotoProvider>
      </div>
    </section>
  );
};

export default GalleryView;
