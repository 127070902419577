import HomeLayout from './layouts/HomeLayout'
import { useEffect } from 'react'
import { Routes, Route, useLocation } from 'react-router'
import { publicRoutes } from './routes'


const App = () => {
  const location = useLocation()

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [location])

  return (
    <div>
      <Routes>
        <Route element={<HomeLayout />}>
          {publicRoutes.map(({ path, element }: any, index: number) => (
            <Route path={path} element={element} key={index} />
          ))}
        </Route>
      </Routes>
    </div>
  )
}

export default App
