import EventCountdownTimer from "components/EventCountdownTimer";
import GalleryView from "components/Gallery";
import { Link } from "react-router-dom";
import "screens/Ministries/styles.scss";
import { JoinMinistry } from "..";

const TheComfortCentreDepartment = () => {
  return (
    <div className="ministries">
      <section
        className="hero-wrap"
        style={{
          backgroundImage: `url('${require("../../../assets/gallery/Welness Center/EZD_5051.JPG")}')`,
          backgroundPositionY: "center",
        }}
      >
        <div className="overlay"></div>
        <div className="container">
          <div className="row no-gutters slider-text justify-content-centers align-items-center">
            <div className="col-md-8 text-left">
              <p data-aos="fade-up" className="breadcrumbs mb-2">
                <span className="mr-2">
                  <Link to="/ministries">
                    Ministries <i className="fa fa-chevron-right"></i>
                  </Link>
                </span>{" "}
                <span>
                  The Comfort Centre <i className="fa fa-chevron-right"></i>
                </span>
              </p>
              <h1 data-aos="fade-up" className="mb-0 bread">
                The Comfort Centre Ministry
              </h1>
            </div>
          </div>
        </div>
        <EventCountdownTimer />
      </section>

      <section className="ftco-section ministry-description">
        <div className="container">
          <div className="row"></div>
          <div className="row">
            <div className="col-md-6">
              <div className="heading-section mb-4">
                <h2>About Ministry</h2>
              </div>
              <p>
                The Comfort Center Ministry at Foursquare Gospel Church VGC is a
                place of solace, compassion, and support. In the heart of our
                church community, the Comfort Center Ministry is dedicated to
                providing comfort, encouragement, and a listening ear to those
                facing life's challenges and uncertainties.
              </p>
              <p>
                In times of sorrow, loss, or distress, our ministry stands as a
                pillar of strength, offering a safe haven for individuals and
                families seeking solace and understanding. Through prayer,
                counseling, and a network of caring individuals, the Comfort
                Center Ministry extends a helping hand to those navigating
                difficult seasons, reminding them that they are not alone.
              </p>
              <p>
                From grief counseling to community outreach, we are committed to
                fostering an environment where everyone can find the comfort
                they need in the embrace of God's love.
              </p>
              <p>
                Join us at Foursquare Gospel Church VGC's Comfort Center
                Ministry, where compassion meets community, and together, we
                journey through life's challenges with the assurance that God's
                comfort is ever-present. Welcome to a place where you can find
                hope, healing, and the strength to face each day with grace.
              </p>{" "}
              <hr />
            </div>
            <div className="col-md-5 offset-md-1">
              <JoinMinistry
                info={{
                  skills:
                    "Have Passion for People, Empathy, Patience, Active Listening, Communication and Counseling Skills.",
                  social_media: [],
                  contact: {
                    people: [
                      {
                        name: "Mrs Chidi Ichaba",
                        email: "comfortcentre@foursquarevgc.org",
                      },
                    ],
                    note: null,
                  },
                }}
              />
            </div>
          </div>
        </div>
      </section>

      {/* <GalleryView
        title="Children Pictures"
        folder="Children"
        numberOfPictures={20}
        imageFormat="JPG"
      /> */}
    </div>
  );
};

export default TheComfortCentreDepartment;
