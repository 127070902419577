import EventCountdownTimer from "components/EventCountdownTimer";
import GalleryView from "components/Gallery";
import { Link } from "react-router-dom";
import "screens/Ministries/styles.scss";
import { JoinMinistry } from "..";

const PrayerDepartment = () => {
  return (
    <div className="ministries">
      <section
        className="hero-wrap"
        style={{
          backgroundImage: `url('${require("../../../assets/gallery/OtherDepartments/Prayers.JPG")}')`,
          backgroundPositionY: "center",
        }}
      >
        <div className="overlay"></div>
        <div className="container">
          <div className="row no-gutters slider-text justify-content-centers align-items-center">
            <div className="col-md-8 text-left">
              <p data-aos="fade-up" className="breadcrumbs mb-2">
                <span className="mr-2">
                  <Link to="/ministries">
                    Ministries <i className="fa fa-chevron-right"></i>
                  </Link>
                </span>{" "}
                <span>
                  Prayer <i className="fa fa-chevron-right"></i>
                </span>
              </p>
              <h1 data-aos="fade-up" className="mb-0 bread">
                Prayer Ministry
              </h1>
            </div>
          </div>
        </div>
        <EventCountdownTimer />
      </section>

      <section className="ftco-section ministry-description">
        <div className="container">
          <div className="row"></div>
          <div className="row">
            <div className="col-md-6">
              <div className="heading-section mb-4">
                <h2>About Ministry</h2>
              </div>
              <p>
                The Prayer Ministry serves as a vital core ministry within the
                church, dedicated to conscientious intercession for the overall
                wellbeing of the Body of Christ. Its responsibilities extend to
                fervent prayer for the evangelistic and missionary efforts of
                the local church, as well as providing prayer support for
                churches in the District.
              </p>{" "}
              <p>
                As of the current performance against key deliverables, the
                Prayer Capacity Building Section aims to launch a new
                curriculum, enroll additional participants, engage more
                instructors, and enhance the utilization of the virtual prayer
                library. The Prayer Section focuses on improving the publicity
                of the Virtual Prayer Room (VPR) every Sunday, organizing
                special prayers related to the VPR, and initiating special
                prayer programs as needed.
              </p>{" "}
              <p>
                {" "}
                In fulfilling these targets, the VPR has been actively
                publicized during Sunday services, mid-week programs, and
                through the church's WhatsApp bot. Despite facing various
                challenges, the Virtual Prayer Room has been sustained, and its
                one-year anniversary was celebrated with testimonies from
                participants. The Project 30/30 Prayer initiative, launched in
                October 2022, aims to pray for Nigeria for 30 minutes every day
                over three periods of 30 days, totaling 90 days of dedicated
                prayer for the fulfillment of the prophecy of Pa Sydney Elton
                over Nigeria.
              </p>
              <p>
                The focus of the Prayer Support Services is to increase
                participation in various prayer platforms, mobilize team members
                responsible for each platform to engage those who have not
                participated over time, and track testimonies from these
                platforms. Additionally, a bi-weekly prayer meeting is held to
                pray for all prayer platforms and upcoming events in the Church.
              </p>
              <p>
                The Church Prayer Programmes Section's sole target for the
                current period is to ensure the smooth implementation of prayer
                programmes and events organized by the Church. To achieve this,
                prayers are offered every Saturday in preparation for the Church
                service on Sunday and other prayer programmes.
              </p>
              <Link to={"https://drive.google.com/drive/folders/1xOVQvuHCB5VN62FBn1_7_DazLVrTLQ8_?usp=drive_link"} target="_blank">
                <button className="btn btn-main"><i className="fa-solid fa-download mr-2"></i>View Prayer School Recordings</button>
              </Link>
              <hr />
            </div>
            <div className="col-md-5 offset-md-1">
              <JoinMinistry
                info={{
                  skills:
                    "Have Passion for Intercessory Prayers, Scriptural Understanding, Patience, Communication and Enthusiasm.",
                  social_media: [],
                  contact: {
                    people: [
                      {
                        name: "Mrs Lolade Ososami",
                        email: "prayerrequest@foursquarevgc.org",
                      },
                    ],
                    note: null,
                  },
                }}
              />
            </div>
          </div>
        </div>
      </section>

      {/* <GalleryView
        title="Prayer Ministry Pictures"
        folder="Children"
        numberOfPictures={20}
        isEmpty={true}
        imageFormat="JPG"
      /> */}
    </div>
  );
};

export default PrayerDepartment;
