import React from "react";
import Logo from "../../assets/icons/compressed/1.webp";
import { Link, useLocation } from "react-router-dom";
import "./styles.scss";

const Navbar = ({ color }: any) => {
  const location = useLocation();
  const isActivePath = (path: string) => location.pathname === path;
  return (
    <React.Fragment>
      <nav
        className={`navbar navbar-expand-lg ftco-navbar-light fixed-top bg-${color}`}
        id="ftco-navbar"
      >
        <div className="container-fluid">
          <Link className="navbar-brand" to="/">
            <img src={Logo} className="img-fluid" alt="foursquare" />
          </Link>
          <button
            className="navbar-toggler"
            type="button"
            data-toggle="collapse"
            data-target="#ftco-nav"
            aria-controls="ftco-nav"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <i className="lni lni-menu"></i>
          </button>

          <div className="collapse navbar-collapse" id="ftco-nav">
            <ul className="navbar-nav ml-auto">
              <li
                className={`nav-item ${isActivePath("/about") ? "active" : ""}`}
              >
                <Link to="https://foursquarvgc.online.church/" target="_blank" rel="noreferrer" className="nav-link">
                  <i className="fa-solid fa-desktop mr-2 mb-0"></i>
                  Online
                </Link>
              </li>
              <li
                className={`nav-item ${isActivePath("/about") ? "active" : ""}`}
              >
                <Link to="/about" className="nav-link">
                  About
                </Link>
              </li>
              <li
                className={`nav-item ${
                  isActivePath("/ministries") ? "active" : ""
                }`}
              >
                <Link to="/ministries" className="nav-link">
                  Ministries
                </Link>
              </li>
              <li
                className={`nav-item  ${isActivePath("/give") ? "active" : ""}`}
              >
                <Link to="/give" className="nav-link">
                  Giving
                </Link>
              </li>
              <li
                className={`nav-item ${
                  isActivePath("/events") ? "active" : ""
                }`}
              >
                <Link to="/events" className="nav-link">
                  Events
                </Link>
              </li>
              <li
                className={`nav-item ${
                  isActivePath("/sermons") ? "active" : ""
                }`}
              >
                <Link to="/sermons" className="nav-link">
                  Sermons
                </Link>
              </li>
            </ul>
            <ul className="navbar-nav ml-auto">
              <li
                className={`nav-item ${
                  isActivePath("/contact") ? "active" : ""
                }`}
              >
                <Link to="/contact" className="nav-link">
                  Contact us
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </React.Fragment>
  );
};

export default Navbar;
