import { Outlet } from "react-router-dom";
import "./styles.scss";
import Navbar from "../../components/Navbar";
import Footer from "../../components/Footer";
import { useState } from "react";

const HomeLayout = () => {
  const [colorChange, setColorchange] = useState(false);
  const changeNavbarColor = () => {
    if (window.scrollY >= 80) {
      setColorchange(true);
    } else {
      setColorchange(false);
    }
  };
  window.addEventListener("scroll", changeNavbarColor);
  return (
    <div>
      <Navbar color={colorChange ? 'white' : 'white'} />
      <Outlet />
      <Footer />
    </div>
  );
};

export default HomeLayout;
