import EventCountdownTimer from "components/EventCountdownTimer";
import GalleryView from "components/Gallery";
import { Link } from "react-router-dom";
import "screens/Ministries/styles.scss";
import { JoinMinistry } from "..";

const MandEDepartment = () => {
  return (
    <div className="ministries">
      <section
        className="hero-wrap"
        style={{
          backgroundImage: `url('${require("../../../assets/gallery/OtherDepartments/MandE.jpeg")}')`,
          backgroundPositionY: "center",
        }}
      >
        <div className="overlay"></div>
        <div className="container">
          <div className="row no-gutters slider-text justify-content-centers align-items-center">
            <div className="col-md-8 text-left">
              <p data-aos="fade-up" className="breadcrumbs mb-2">
                <span className="mr-2">
                  <Link to="/ministries">
                    Ministries <i className="fa fa-chevron-right"></i>
                  </Link>
                </span>{" "}
                <span>
                  Mission and Evangelism <i className="fa fa-chevron-right"></i>
                </span>
              </p>
              <h1 data-aos="fade-up" className="mb-0 bread">
                Mission and Evangelism Ministry
              </h1>
            </div>
          </div>
        </div>
        <EventCountdownTimer />
      </section>

      <section className="ftco-section ministry-description">
        <div className="container">
          <div className="row"></div>
          <div className="row">
            <div className="col-md-6">
              <div className="heading-section mb-4">
                <h2>About Ministry</h2>
              </div>
              <p>
                The Missions and Evangelism Ministry of Foursquare
                VGC carries the significant responsibility of leading the entire
                Church in fulfilling the Great Commission - "Go ye into the
                world and preach the gospel..." This is a call we do not take
                lightly. Our understanding of our role as a Ministry is not to
                act alone, but rather to mobilize and unite the entire church in
                spreading the gospel to everyone through various programmes and
                initiatives. 
                </p>
                <p>
                We firmly believe that this responsibility does not
                fall solely on a select few but on every member of the Kingdom
                who worships in our local assembly. While we do have dedicated
                members in our Ministry responsible for planning and executing
                activities to fulfill the Great Commission, we acknowledge that
                the call to go and make disciples is a collective responsibility
                for all of us.
              </p>{" "}
              <b>Church Planting and Pioneering </b>
              <p>
                We completed the payment for the additional land bought for our
                Foursquare Gospel Church, Adiva Plainfields. That Church is now
                fully connected to the Estate‟s utilities.The impact of the
                pandemic was hard on them but the Lord is helping them and we
                trust Him for a full recovery and even advancement on all
                spiritual and ministry fronts in the new Church year by God‟s
                grace. Evangelism Our Missions and Evangelism Ministry defied
                the lockdown and isolation as they creatively launched their{" "}
              </p>
              <b>e-Vangelism. </b>
              <p>
                They have leveraged this and various platforms to continue their
                evangelistic activities online. We thank God for the impact this
                is making and pray the Lord will continue to encourage more of
                our members to participate and grant us more access. We are
                confident that by God‟s grace we shall resume our usual physical
                Evangelism every first and second Saturday of the month sometime
                soon.{" "}
              </p>
              <b>Missions </b>
              <p>
                We continue to support and partner with some Missionaries
                serving in different fields and under missions agencies. We also
                support some evangelistic ministries and projects.
              </p>
              <hr />
            </div>
            <div className="col-md-5 offset-md-1">
              <JoinMinistry
                info={{
                  skills:
                    "Have Passion for Missions, Communication, Listening, Patience and Enthusiasm.",
                  social_media: [],
                  contact: {
                    people: [
                      {
                        name: "Olorunsogo Kolawole",
                        email: "missions@foursquarevgc.org",
                      },
                    ],
                    note: null,
                  },
                }}
              />
            </div>
          </div>
        </div>
      </section>

      {/* <GalleryView
        title="Mission and Evangelism Pictures"
        folder="MandE"
        numberOfPictures={20}
        isEmpty={true}
        imageFormat="JPG"
      /> */}
    </div>
  );
};

export default MandEDepartment;
