import { Link } from "react-router-dom";
import "./styles.scss";

const Footer = () => {
  return (
    <footer className="footer">
      <div className="container">
        <div className="row">
          <div className="col-md-6 col-lg-4 mb-4 mb-md-0">
            <h2 className="footer-heading main">Foursquare Church, VGC</h2>
            <p>
              Grow in your faith, learn about God’s plan for your life and be
              made ready for the coming of our Lord Jesus Christ.
            </p>
            <ul className="ftco-footer-social p-0">
              <li className="ftco-animated">
                <a
                  href="https://m.youtube.com/@FoursquareChurchVGCLagos/streams"
                  data-toggle="tooltip"
                  rel="noreferrer"
                  target="_blank"
                  data-placement="top"
                  title="Twitter"
                >
                  <span className="fa fa-youtube"></span>
                </a>
              </li>
              <li className="ftco-animated">
                <a
                  href="https://www.facebook.com/FoursquareGospelChurchVgc/"
                  data-toggle="tooltip"
                  rel="noreferrer"
                  target="_blank"
                  data-placement="top"
                  title="Facebook"
                >
                  <span className="fa fa-facebook"></span>
                </a>
              </li>
              <li className="ftco-animated">
                <a
                  href="https://www.instagram.com/foursquarevgc/?hl=en"
                  data-toggle="tooltip"
                  rel="noreferrer"
                  target="_blank"
                  data-placement="top"
                  title="Instagram"
                >
                  <span className="fa fa-instagram"></span>
                </a>
              </li>
              <li className="ftco-animated">
                <a
                  href="https://www.tiktok.com/@foursquarevgc"
                  data-toggle="tooltip"
                  rel="noreferrer"
                  target="_blank"
                  data-placement="top"
                  title="Tiktok"
                >
                  <span className="fa-brands fa-tiktok"></span>
                </a>
              </li>
              <li className="ftco-animated">
                <a
                  href="https://wa.me/message/MK7TQXBGOBAOM1"
                  data-toggle="tooltip"
                  rel="noreferrer"
                  target="_blank"
                  data-placement="top"
                  title="WhatsApp"
                >
                  <span className="fa-brands fa-whatsapp"></span>
                </a>
              </li>
            </ul>
          </div>
          <div className="col-md-6 col-lg-3 pl-lg-5 mb-4 mb-md-0">
            <h2 className="footer-heading">Quick Links</h2>
            <ul className="list-unstyled">
              <li>
                <Link to="/" className="py-2 d-block">
                  Home
                </Link>
              </li>
              <li>
                <Link to="/about" className="py-2 d-block">
                  About
                </Link>
              </li>
              <li>
                <Link to="/ministries" className="py-2 d-block">
                  Ministries
                </Link>
              </li>
              <li>
                <Link to="/events" className="py-2 d-block">
                  Events
                </Link>
              </li>
              <li>
                <Link to="/sermons" className="py-2 d-block">
                  Sermons
                </Link>
              </li>
              <li>
                <Link to="/contact" className="py-2 d-block">
                  Contact
                </Link>
              </li>
            </ul>
          </div>
          <div className="col-md-6 col-lg-5 mb-4 mb-md-0">
            <h2 className="footer-heading">Have a Questions?</h2>
            <div className="block-23 mb-3">
              <ul>
                <li>
                  <span className="icon fa fa-map"></span>
                  <span className="text">
                    Plot PC, Road 8B (Business Section), Victoria Garden City
                    (VGC), Lagos, Nigeria
                  </span>
                </li>
                <li>
                  <a href="tel://2348082479604" className="mb-0">
                    <span className="icon fa fa-phone"></span>
                    <span className="text">+234-808-2479-604</span>
                    &nbsp;(Counselling)
                  </a>
                </li>
                <li>
                  <a href="tel://23417317138" className="mb-0">
                    <span className="icon fa fa-phone"></span>
                    <span className="text">+234-1-7317138</span>&nbsp;(Church
                    office)
                  </a>
                </li>
                <li>
                  <a href="tel://2348022914305" className="mb-0">
                    <span className="icon fa fa-phone"></span>
                    <span className="text">+234-802-2914-305</span>
                    &nbsp;(Emergencies)
                  </a>
                </li>
                <li>
                  <a href="mailto:churchoffice@foursquarevgc.org">
                    <span className="icon fa fa-paper-plane"></span>
                    <span className="text">churchoffice@foursquarevgc.org</span>
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
