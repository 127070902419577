import EventCountdownTimer from "components/EventCountdownTimer";
import GalleryView from "components/Gallery";
import { Link } from "react-router-dom";
import "screens/Ministries/styles.scss";

const FamilyMinistry = () => {
  return (
    <div className="ministries">
      <section className="hero-wrap">
        <div className="overlay"></div>
        <div className="container">
          <div className="row no-gutters slider-text align-items-center">
            <div className="col-md-8 text-left ftco-animated">
              <p data-aos="fade-up" className="breadcrumbs mb-2">
                <span className="mr-2">
                  <Link to="/ministries">
                    Ministries <i className="fa fa-chevron-right"></i>
                  </Link>
                </span>{" "}
                <span>
                  Family Life <i className="fa fa-chevron-right"></i>
                </span>
              </p>
              <h1 data-aos="fade-up" className="mb-0 bread">
                Family Life
              </h1>
              <p data-aos="fade-up" className="sub-text">
                A gathering of inspiring women, empowering one another through
                shared experiences, fellowship, fostering deep connections
                relationships.
              </p>
            </div>
          </div>
        </div>
        <EventCountdownTimer />
      </section>

      <section className="ftco-section wellspring-bg ministry-description">
        <div className="container">
          <div className="row"></div>
          <div className="row align-items-center">
            <div className="col-md-6">
              <div className="heading-section mb-4">
                <h2 className="text-white">
                  Discover a sisterhood of faith and empowerment in our Ladies
                  Ministry
                </h2>
              </div>
              <p>
                We are a community of women who come together to support,
                encourage and empower each other through faith and fellowship.
                Our Bible studies, workshops and events are tailored to meet the
                needs of today's women. We believe that every woman has the
                potential to be a leader in her own right, and we strive to
                empower each other through our shared faith in God.
              </p>

              <p>
                Join us and discover a sisterhood of faith and empowerment as we
                grow together in our relationship with God and serve others. We
                look forward to meeting you and growing together in faith and
                friendship.
              </p>
              <button className="btn btn-transparent-outline mt-4">
                Worship with us
              </button>
            </div>
            <div className="col-md-5 offset-md-1">
              <img
                src={require("../../../assets/images/compressed/6.webp")}
                alt="women"
                className="img-fluid mb-4"
              />
              <img
                src={require("../../../assets/images/compressed/10.webp")}
                alt="women"
                className="img-fluid"
              />
            </div>
          </div>
        </div>
      </section>
      <GalleryView title="Family Pictures" folder="Men" numberOfPictures={4} />
    </div>
  );
};

export default FamilyMinistry;
