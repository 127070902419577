import Swal from "sweetalert2";
import { useState } from "react";
import { Modal } from "antd";

const NewConvertForm = ({ buttonColor = "", isVisible, closeModal }: any) => {
  return (
    <Modal
      className="join-ministry-form"
      title="New Convert Form"
      open={isVisible}
      onCancel={closeModal}
      footer={null}
    >
      <NewConvertFormContent buttonColor={buttonColor} closeModal={closeModal} />
    </Modal>
  );
};

export default NewConvertForm;

const NewConvertFormContent = ({ buttonColor, closeModal }: any) => {
  const [contact, setContact] = useState({
    email: "",
    subject: "Want to Volunteer",
    honeypot: "", // if any value received in this field, form submission will be ignored.
    message: "",
    $firstname: "",
    $lastname: "",
    phone: "",
    replyTo: "churchoffice@foursquarevgc.org", // this will set replyTo of email to email address entered in the form
    accessKey: "9b4f7d5b-8975-43af-879d-e45e1f25592e", // get your access key from https://www.staticforms.xyz
  });

  const handleChange = (e: any) =>
    setContact({ ...contact, [e.target.name]: e.target.value });

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    try {
      const res = await fetch("https://api.staticforms.xyz/submit", {
        method: "POST",
        body: JSON.stringify(contact),
        headers: { "Content-Type": "application/json" },
      });

      const json = await res.json();

      if (json.success) {
        Swal.fire({
          title: "Form Successfully Submitted!",
          text: "Thank you for reaching out to us.",
          icon: "success",
        }).then(() => {
          setContact({
            email: "",
            subject: "",
            honeypot: "", // if any value received in this field, form submission will be ignored.
            message: "",
            $firstname: "",
            $lastname: "",
            phone: "",
            replyTo: "churchoffice@foursquarevgc.org", // this will set replyTo of email to email address entered in the form
            accessKey: "9b4f7d5b-8975-43af-879d-e45e1f25592e", // get your access key from https://www.staticforms.xyz
          });
          closeModal();
        });
      } else {
        Swal.fire({
          title: "An error occurred!",
          text: json.message,
          icon: "error",
          confirmButtonText: "Try again",
        });
      }
    } catch (e) {
      console.log("An error occurred", e);
      Swal.fire({
        title: "An error occurred!",
        text: "Please try submitting the form again",
        icon: "error",
        confirmButtonText: "Try again",
      });
    }
  };

  return (
    <div>
      <form
        action="https://api.staticforms.xyz/submit"
        method="post"
        onSubmit={handleSubmit}
        id="contactForm"
        name="contactForm"
        className="contactForm"
      >
        <div className="row">
          <div className="col-md-6">
            <div className="form-group">
              <label className="label" htmlFor="firstname">
                First Name
              </label>
              <input
                type="text"
                className="form-control"
                name="$firstname"
                onChange={handleChange}
                id="firstname"
                placeholder="First Name"
                required
              />
            </div>
          </div>
          <div className="col-md-6">
            <div className="form-group">
              <label className="label" htmlFor="lastname">
                Last Name
              </label>
              <input
                type="text"
                className="form-control"
                name="$lastname"
                onChange={handleChange}
                id="lastname"
                placeholder="Last Name"
                required
              />
            </div>
          </div>
          <div className="col-md-12">
            <div className="form-group">
              <label className="label" htmlFor="email">
                Email
              </label>
              <input
                type="email"
                className="form-control"
                name="email"
                onChange={handleChange}
                id="email"
                placeholder="Email"
              />
            </div>
          </div>
          <div className="col-md-12">
            <div className="form-group">
              <label className="label" htmlFor="phone">
                Phone Number
              </label>
              <input
                type="text"
                className="form-control"
                name="phone"
                onChange={handleChange}
                id="phone"
                placeholder="Phone Number"
                required
              />
            </div>
          </div>
          <div className="col-md-12">
            <div className="form-group">
              <label className="label" htmlFor="#">
                Comments/Prayer Requests
              </label>
              <textarea
                name="message"
                className="form-control"
                id="message"
                cols={30}
                onChange={handleChange}
                rows={4}
                placeholder="Comments/Prayer Requests"
              ></textarea>
            </div>
          </div>
          <div className="col-md-12">
            <div className="form-group">
              <button
                className={`btn ${
                  !buttonColor ? "btn-main" : "btn-main-" + buttonColor
                } `}
                type="submit"
              >
                Submit
              </button>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};
