import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import dayjs from "dayjs";
import utc from 'dayjs/plugin/utc'
import tz from 'dayjs/plugin/timezone'
import "./styles.scss";

// require('dayjs-ext');

dayjs.extend(utc)
dayjs.extend(tz)
dayjs.tz.setDefault('Africa/Lagos');

const calculateTimeLeft = (targetDate: any) => {
  // const difference = +new Date(targetDate) - +new Date();

  const now = dayjs()
  const difference = dayjs(targetDate).diff(now);
  let timeLeft = {};

  if (difference > 0) {
    timeLeft = {
      days: Math.floor(difference / (1000 * 60 * 60 * 24)),
      hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
      minutes: Math.floor((difference / 1000 / 60) % 60),
      seconds: Math.floor((difference / 1000) % 60),
    };
  }

  return timeLeft;
};

interface TimeInterface {
  days: number;
  hours: number;
  minutes: number;
  seconds: number;
}

const CountdownTimer = ({ targetDate }: any) => {
  const [timeLeft, setTimeLeft] = useState<TimeInterface | any>(
    calculateTimeLeft(targetDate)
  );

  useEffect(() => {
    const timer = setInterval(() => {
      setTimeLeft(calculateTimeLeft(targetDate));
    }, 1000);

    return () => {
      clearInterval(timer);
    };
  }, [targetDate]);

  return (
    <div className="countdown">
      <div>
        <h3>{timeLeft.days}</h3>
        <p>days</p>
      </div>
      <div>
        <h3>{timeLeft.hours}</h3>
        <p>hours</p>
      </div>
      <div>
        <h3>{timeLeft.minutes}</h3>
        <p>minutes</p>
      </div>
      <div>
        <h3>{timeLeft.seconds}</h3>
        <p>seconds</p>
      </div>
    </div>
  );
};

export const generateDesiredStartEndDate = (
  day: number,
  startHour: number,
  startMinute: number,
  endHour: number,
  endMinute: number,
) => {
  const now = dayjs();
  let nextDesiredDay = now.startOf("week").add(day, "day"); // set desired day
  if (nextDesiredDay.isBefore(now) || nextDesiredDay.isSame(now, "day")) {
    nextDesiredDay = nextDesiredDay.add(1, "week");
  }
  const startDesiredTime = nextDesiredDay
    .set("hour", startHour)
    .set("minute", startMinute)
    .set("second", 0);
  
  const endDesiredTime = nextDesiredDay
    .set("hour", endHour)
    .set("minute", endMinute)
    .set("second", 0);

  // Get the timestamp
  const startDate = startDesiredTime.format('YYYY-MM-DD');
  const startTime = startDesiredTime.format('HH:MM')
  const endDate = endDesiredTime.format('YYYY-MM-DD');
  const endTime = endDesiredTime.format('HH:MM')
  return { startDate, startTime, endDate, endTime };
};

const EventCountdownTimer = () => {
  // Get the current date and time
  const now = dayjs();

  const generateDesiredTimestamp = (
    day: number,
    hour: number,
    minute: number
  ) => {
    let nextDesiredDay = now.startOf("week").add(day, "day"); // set desired day
    if (nextDesiredDay.isBefore(now) || nextDesiredDay.isSame(now, "day")) {
      nextDesiredDay = nextDesiredDay.add(1, "week");
    }
    const desiredTime = nextDesiredDay
      .set("hour", hour)
      .set("minute", minute)
      .set("second", 0);

    // Get the timestamp
    const timestamp = desiredTime.valueOf();
    return timestamp;
  };

  const [selectedDate, setSelectedDate] = useState<any>(null);
  const [events] = useState<any>([
    {
      title: "Sunday Service",
      timestamp: generateDesiredTimestamp(0, 9, 20), // day of the week, hour of the day and minutes
      description: 'This is a sample event.',
      location: 'Event Location'
    },
    {
      title: "Bible Study",
      timestamp: generateDesiredTimestamp(3, 18, 30),
      description: 'This is a sample event.',
      location: 'Event Location',
    },
    // {
    //   title: "Virtual Prayer Room",
    //   timestamp: generateDesiredTimestamp(5, 18, 30),
    //   description: 'This is a sample event.',
    //   location: 'Event Location',
    // },
  ]);

  const selectNextDate = (events: any) => {
    const futureTimestamps = events.filter(({ timestamp }: any) =>
      dayjs(timestamp).isAfter(now)
    );
    // Sort future timestamps in ascending order
    const sortedTimestamps = futureTimestamps.sort((a: any, b: any) => a.timestamp - b.timestamp);
    // Get the earliest happening timestamp
    const nextTimestamp = sortedTimestamps[0];
    setSelectedDate(nextTimestamp);
  };

  useEffect(() => {
    selectNextDate(events);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [events]);

  const targetDate = selectedDate ? dayjs(selectedDate?.timestamp).toDate() : null;
  
  const { title } = selectedDate || "";
  
  return selectedDate && (
    <div className="event-countdown">
      <div className="container">
        <div className="row justify-content-between align-items-center">
          <div>
            <p>Next Service</p>
            <h5>{title}</h5>
          </div>
          <div>
            <CountdownTimer targetDate={targetDate} />
          </div>
          <div>
            <Link to="/events">
              <button className="btn btn-md btn-view-all-events">
                View all events
              </button>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EventCountdownTimer;
