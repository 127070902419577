import EventCountdownTimer from "components/EventCountdownTimer";
import GalleryView from "components/Gallery";
import { Link } from "react-router-dom";
import "screens/Ministries/styles.scss";
import { JoinMinistry } from "..";

const ChildrenMinistry = () => {
  return (
    <div className="ministries">
      <section
        className="hero-wrap"
        style={{
          backgroundImage: `url('${require("../../../assets/gallery/Children/compressed/17.webp")}')`,
          backgroundPositionY: 'bottom'
        }}
      >
        <div className="overlay"></div>
        <div className="container">
          <div className="row no-gutters slider-text justify-content-centers align-items-center">
            <div className="col-md-8 text-left">
              <p data-aos="fade-up" className="breadcrumbs mb-2">
                <span className="mr-2">
                  <Link to="/ministries">
                    Ministries <i className="fa fa-chevron-right"></i>
                  </Link>
                </span>{" "}
                <span>
                  Children Church <i className="fa fa-chevron-right"></i>
                </span>
              </p>
              <h1 data-aos="fade-up" className="mb-0 bread">
                Children Church
              </h1>
              <p data-aos="fade-up" className="sub-text">
                Raising Children to have a genuine encounter with the Lord Jesus.
              </p>
            </div>
          </div>
        </div>
        <EventCountdownTimer />
      </section>

      <section className="ftco-section ministry-description">
        <div className="container">
          <div className="row"></div>
          <div className="row">
            <div className="col-md-6">
              <div className="heading-section mb-4">
                <h2>
                  About Ministry
                </h2>
              </div>
              <p>
              The Children Ministry, with 8 members: 1 youth, 7 adults, has a goal in creating a spiritual atmosphere where the core spiritual truths are imparted and children can experience God and develop daily interaction with the Most High God.
              </p>{" "}
              <hr /> 
              <h3>How we achieve our objectives</h3>
              <ul className="sc-Arkif eJDRKz"><li>Bible lessons through Sunday School classes</li><li>Using colorful and picturesque PowerPoint presentation</li><li>Training for our teachers</li></ul>
            </div>
            <div className="col-md-5 offset-md-1">
            <JoinMinistry
                info={{
                  skills: "Have passion for children, Patience, Communication and enthusiasm.",
                  social_media: [],
                  contact: {
                    people: [
                      {
                        name: "Sister Mary Kanu",
                        email: "childrenchurch@foursquarevgc.org"
                      }
                    ],
                    note: (
                      <p className="sc-fkmfBh jJpdDo">
                        Please go through the teacher training dept of the Sunday School.
                      </p>
                    ),
                  },
                }}
              />
            </div>
          </div>
        </div>
      </section>

      <GalleryView
        title="Children Pictures"
        folder="Children"
        numberOfPictures={20}
        imageFormat="webp"
      />
    </div>
  );
};

export default ChildrenMinistry;
