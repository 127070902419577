import CalendarIcon from "../../assets/icons/calendar.svg";
import EventCountdownTimer from "components/EventCountdownTimer";
import LiteYouTubeEmbed from "react-lite-youtube-embed";
import "react-lite-youtube-embed/dist/LiteYouTubeEmbed.css";
import "./styles.scss";
import axios from "axios";
import { useEffect, useState } from "react";
import moment from "moment";

const YOUR_API_KEY = "AIzaSyDDYURfUPzlByCIU-h_RxsGvenSxdeWV2M";
const YOUR_PLAYLIST_ID = "PLDIKHVCJwI7keMn2YxZLNPKERslcBq7RA";
const YOUR_CHANNEL_ID = "UC6BAJ94qVU3-NWXirp1nt5Q";
const baseURL = `https://www.googleapis.com/youtube/v3/search?part=snippet&channelId=${YOUR_CHANNEL_ID}&key=${YOUR_API_KEY}&maxResults=9&order=date`;
// const baseURL = `https://www.googleapis.com/youtube/v3/playlistItems?part=snippet&playlistId=${YOUR_PLAYLIST_ID}&key=${YOUR_API_KEY}&maxResults=9&order=date`;

const Sermons = () => {
  const [sermons, setSermons] = useState([]);
  const [sermonsLoading, setSermonsLoading] = useState(true);

  useEffect(() => {
    const fetchSermons = async () => {
      await axios
        .get(baseURL)
        .then((response) => {
          console.log(response.data);
          setSermons(response.data.items);
          setSermonsLoading(false);
        })
        .catch((error) => {
          console.log(error);
          setSermonsLoading(false);
        });
    };

    fetchSermons();
  }, []);

  return (
    <div className="sermons">
      <section className="hero-wrap">
        <div className="overlay"></div>
        <div className="container">
          <div className="row no-gutters slider-text justify-content-center align-items-center">
            <div className="col-md-9">
              <h1 className="mb-0 bread">Listen to Sermons</h1>
              <p className="sub-text">
                Relive and catch up on sermons from previous weeks.
              </p>
              <div
                className="d-flex justify-content-center flex-wrap mt-4"
                style={{ gap: "10px" }}
              >
                <a
                  href="https://m.youtube.com/@FoursquareChurchVGCLagos/streams"
                  rel="noreferrer"
                  target="_blank"
                  data-aos="fade-in"
                >
                  <button className="btn btn-md btn-main ">
                    <div className="content">
                      <i className="fa-solid fa-circle-play"></i>
                      Watch Latest Sermon
                    </div>
                  </button>
                </a>
                <a
                  href="https://podcasters.spotify.com/pod/show/foursquare-vgc"
                  rel="noreferrer"
                  target="_blank"
                  data-aos="fade-in"
                >
                  <button className="btn btn-md btn-main">
                    <div className="content">
                      <i className="fa-solid fa-music"></i>
                      Listen to Latest Sermon
                    </div>
                  </button>
                </a>
              </div>
            </div>
          </div>
        </div>
        <EventCountdownTimer />
      </section>

      <section className="ftco-section ftco-no-pb ftco-no-pts bg-section1">
        <div className="container">
          <div className="row no-gutters justify-content-center align-items-center mb-4">
            <div className="col-md-4 heading-section text-left ftco-animated">
              <h2 className="mb-0">Past Sermons</h2>
            </div>
            <div className="col-md-8 heading-section text-right ftco-animated">
              <a
                href="https://drive.google.com/drive/folders/1GjWv88rsR92LEcR11TNGybG9kHKG1p8q?usp=drive_link"
                rel="noreferrer"
                target="_blank"
                data-aos="fade-in"
              >
                <button className="btn btn-sermon-outline mt-0">
                  <i className="fa-solid fa-download mr-2"></i> Download Sermon
                  Outlines
                </button>
              </a>
            </div>
          </div>
          <div className="row mt-4">
            {sermonsLoading ? "Loading..." : sermons.map(
              (
                {
                  id: { videoId },
                  snippet: { title, description, publishedAt },
                },
                index
              ) => (
                <div className="col-md-4 d-flex h-100" key={index}>
                  <div className="sermon-item h-100">
                    <LiteYouTubeEmbed id={videoId} title={title} />
                    <div className="text">
                      <p className="d-flex align-items-center">
                        <img
                          src={CalendarIcon}
                          className="img-fluid mr-2"
                          alt="calendar"
                        />{" "}
                        {moment.utc(publishedAt).format("LL")}
                      </p>
                      <h2 className="mb-2s">{title}</h2>
                      {/* <p>{description}</p> */}
                    </div>
                  </div>
                </div>
              )
            )}
          </div>
          <div className="row">
            <div className="col-md-12 text-center mt-4s">
              <div
                className="d-flex justify-content-center"
                style={{ gap: "20px" }}
              >
                <a
                  href="https://m.youtube.com/@FoursquareChurchVGCLagos/streams"
                  rel="noreferrer"
                  target="_blank"
                  data-aos="fade-in"
                >
                  <button className="btn btn-main">
                    See all Sermons{" "}
                    <i className="fa-solid fa-arrow-right ml-2"></i>
                  </button>
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Sermons;
