import EventCountdownTimer from "components/EventCountdownTimer";
import GalleryView from "components/Gallery";
import { Link } from "react-router-dom";
import "screens/Ministries/styles.scss";
import { JoinMinistry } from "..";

const MenMinistry = () => {
  return (
    <div className="ministries">
      <section
        className="hero-wrap"
        style={{
          backgroundImage: `url('${require("../../../assets/images/compressed/3.webp")}')`,
        }}
      >
        <div className="overlay"></div>
        <div className="container">
          <div className="row no-gutters slider-text justify-content-centers align-items-center">
            <div className="col-md-8 text-left ftco-animated">
              <p className="breadcrumbs mb-2">
                <span className="mr-2">
                  <Link to="/ministries">
                    Ministries <i className="fa fa-chevron-right"></i>
                  </Link>
                </span>{" "}
                <span>
                  Men Ministry <i className="fa fa-chevron-right"></i>
                </span>
              </p>
              <h1 className="mb-0 bread">Men Ministry</h1>
              <p className="sub-text">
                The Council of Foursquare Men (CFM) exists as a body of men of
                18 years and above. Any man fulfilling them is tagged a “Real
                Man”.
              </p>
            </div>
          </div>
        </div>
        <EventCountdownTimer />
      </section>

      <section className="ftco-section ministry-description">
        <div className="container">
          <div className="row"></div>
          <div className="row">
            <div className="col-md-6">
              <div className="heading-section mb-4">
                <h2>About Ministry</h2>
              </div>
              <p>
                Welcome to "The Council of Foursquare Men (CFM)," an integral
                part of our spiritual family.
              </p>
              <p>
                The Council of Foursquare Men is a dynamic assembly within our
                church, committed to embodying the principles of Christian
                manhood and service. Rooted in the Foursquare Gospel Church
                VGC's mission, CFM stands as a pillar of strength, fostering a
                community of men dedicated to spiritual growth, leadership, and
                service to both the church and the broader society.
              </p>
              <p>
                In the recent church year, CFM played a pivotal role in various
                initiatives, embodying the spirit of service and brotherhood.
                Through collaborative efforts, they engaged in impactful
                community projects, exemplifying the love of Christ through
                practical deeds. These dedicated men also actively participated
                in church events, providing leadership and support where needed.
              </p>
              <p>
                As part of the broader church family, CFM aligns with the vision
                of the Foursquare Gospel Church VGC, seeking to uplift and
                inspire men to walk in the path of righteousness. Join us in
                celebrating the achievements, growth, and collective spirit of
                The Council of Foursquare Men, as we continue to stand together
                in faith and service, building a stronger community and making a
                lasting impact for the glory of God. Welcome to the CFM
                brotherhood at Foursquare Gospel Church VGC!
              </p>
            </div>
            <div className="col-md-5 offset-md-1">
              <JoinMinistry
                info={{
                  skills: "",
                  social_media: [
                    {
                      icon: <i className="fa fa-twitter" />,
                      link: "/",
                    },
                    {
                      icon: <i className="fa fa-instagram" />,
                      link: "/",
                    },
                    {
                      icon: <i className="fa fa-link" />,
                      link: "/",
                    },
                  ],
                  contact: {
                    people: [
                      
                    ],
                    note: (
                      <p className="sc-fkmfBh jJpdDo">
                        Reach out to the administrative team by email on{" "}
                        <a href="mailto:cfm@foursquarevgc.org">
                          cfm@foursquarevgc.org
                        </a>{" "}
                        or call{" "}
                        <a href="tel: +2348071556176">+234 807 155 6176</a>
                      </p>
                    ),
                  },
                }}
              />
            </div>
          </div>
        </div>
      </section>

      <GalleryView title="Men Pictures" folder="Men" numberOfPictures={4} />
    </div>
  );
};

export default MenMinistry;
