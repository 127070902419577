import { useEffect, useState } from "react";
import axios from "axios";
import { AddToCalendarButton } from "add-to-calendar-button-react";
import EventCountdownTimer from "components/EventCountdownTimer";
import "./styles.scss";
import moment from "moment";
import { PhotoProvider, PhotoView } from "react-photo-view";
import "react-photo-view/dist/react-photo-view.css";

const REACT_APP_CALENDAR_ID =
  "3711bf2089fc77d3df4577588bc27e677ebeef874b0c6b9da52077fd7bf09e6e@group.calendar.google.com";
const REACT_APP_GOOGLE_API_KEY = "AIzaSyDDYURfUPzlByCIU-h_RxsGvenSxdeWV2M";

const Events = () => {
  const [events, setEvents] = useState<any>([]);

  useEffect(() => {
    const fetchEvents = async () => {
      try {
        const response = await axios.get(
          `https://www.googleapis.com/calendar/v3/calendars/${REACT_APP_CALENDAR_ID}/events`,
          {
            params: {
              key: REACT_APP_GOOGLE_API_KEY,
              singleEvents: true,
              orderBy: "startTime",
            },
          }
        );

        const data = response.data.items;

        const eventsData = data.map((item: any) => {
          const googleDriveLink =
            item?.attachments.map(({ fileUrl }: any) => fileUrl)[0] || "";

          const images =
            item?.attachments.map(({ fileUrl }: any) => {
              return `https://drive.google.com/uc?export=view&id=${extractIdFromUrl(
                fileUrl
              )}`;
            }) || [];

          return {
            title: item?.summary,
            description: item?.summary,
            location: item?.location,
            startDate: item?.start.dateTime,
            startTime: item?.start.dateTime,
            endDate: item?.end.dateTime,
            endTime: item?.end.dateTime,
            image: `https://drive.google.com/uc?export=view&id=${extractIdFromUrl(
              googleDriveLink
            )}`,
            images,
            // image: `https://drive.google.com/thumbnail?id=${extractIdFromUrl(googleDriveLink)}`,
          };
        });

        setEvents(eventsData);
      } catch (error) {
        console.error("Error fetching events:", error);
      }
    };

    fetchEvents();
  }, []);

  const extractIdFromUrl = (url: string) => {
    const regex = /id=([^&]+)/;
    const match = url.match(regex);
    return match ? match[1] : null;
  };

  return (
    <div className="events">
      <section
        className="hero-wrap"
        style={{
          backgroundImage: `url('${require("../../assets/gallery/TestimonyNight/compressed/4.webp")}')`,
        }}
      >
        <div className="overlay"></div>
        <div className="container">
          <div className="row no-gutters slider-text justify-content-center align-items-center">
            <div className="col-md-6">
              <h1 data-aos="fade-up" className="mb-0 bread">
                Events
              </h1>
              <p data-aos="fade-up" className="sub-text">
                Stay connected and up to date with happening events in the
                Foursquare gospel church
              </p>
            </div>
          </div>
        </div>
        <EventCountdownTimer />
      </section>

      <section className="ftco-section upcoming-event">
        <div className="container">
          <div className="row justify-content-center mb-3">
            <div className="col-md-12 heading-section text-left ftco-animate2">
              <h2>Upcoming Events</h2>
            </div>
          </div>
          <div className="row">
            {events.length === 0 ? (
              <div className="col-md-12">
                <p style={{ fontSize: "18px" }}>
                  <i>There are no events right now, please check back later.</i>
                </p>
              </div>
            ) : (
              events.map(
                (
                  {
                    title,
                    description,
                    location,
                    image,
                    images,
                    startDate,
                    startTime,
                    endDate,
                    endTime,
                  }: any,
                  index: number
                ) => (
                  <div className="col-md-6 d-md-flexs" key={index}>
                    <div className="event-item">
                      <div className="row align-items-center">
                        <div className="col-md-12">
                          <PhotoProvider>
                            <PhotoView key={title + index + "new"} src={image}>
                              <img
                                src={image}
                                alt="events"
                                className="img-fluid"
                              />
                            </PhotoView>
                            {images.length > 0 &&
                              images.slice(1).map((image: string, index: number) => (
                                <PhotoView key={title + index} src={image}>
                                  <img
                                    src={image}
                                    style={{display: 'none'}}
                                    alt="events"
                                    className="img-fluid"
                                  />
                                </PhotoView>
                              ))}
                          </PhotoProvider>
                        </div>
                        <div className="col-md-12 d-flexs">
                          <div className="text pt-4">
                            <h5 className="mb-2">{title}</h5>
                            <p>{description}</p>
                            <p className="mb-0">
                              <i className="fa fa-map-marker mr-2" /> {location}
                            </p>
                            <p className="mb-0">
                              <i className="fa-regular fa-calendar mr-2" />{" "}
                              {moment(startDate).format("LL")}
                            </p>
                            <p>
                              <i className="fa-regular fa-clock mr-2" />{" "}
                              {moment(startDate).format("HH:mm a")} to{" "}
                              {moment(endDate).format("HH:mm a")}
                            </p>
                            <AddToCalendarButton
                              name={title}
                              description={description}
                              options={["Google"]}
                              location={location}
                              startDate={moment(startDate).format("YYYY-MM-DD")}
                              endDate={moment(endDate).format("YYYY-MM-DD")}
                              startTime={moment(startDate).format("HH:mm")}
                              endTime={moment(endDate).format("HH:mm")}
                              timeZone="Africa/Lagos"
                              trigger="click"
                              hideBackground={true}
                              hideCheckmark={true}
                              buttonStyle="custom"
                              customCss="css/button.css"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )
              )
            )}
          </div>
        </div>
      </section>
    </div>
  );
};

export default Events;

const events2 = [
  {
    title: "Food Distribution Ministry",
    description: "Provisions Ministry food and diaper distribution.",
    location: "Foursquare gospel church",
    date: "31 July, 2021",
    time: "6pm - 8pm",
    startDate: "2023-07-21",
    startTime: "18:07",
    endDate: "2023-07-21",
    endTime: "18:07",
    image:
      "https://images.pexels.com/photos/6646989/pexels-photo-6646989.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2",
  },
  {
    title: "Writers Ministry",
    description:
      "Writers in his vineyard: A purposeful way to writing for the Lord",
    location: "Foursquare gospel church",
    date: "31 July, 2021",
    time: "6pm - 8pm",
    startDate: "2023-07-21",
    startTime: "18:07",
    endDate: "2023-07-21",
    endTime: "18:07",
    image:
      "https://images.pexels.com/photos/1766604/pexels-photo-1766604.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2",
  },
  {
    title: "Marriage Ministry",
    description: "Virtual marriage prep with Pastor Okien Peters",
    location: "Foursquare gospel church",
    date: "31 July, 2021",
    time: "6pm - 8pm",
    startDate: "2023-07-21",
    startTime: "18:07",
    endDate: "2023-07-21",
    endTime: "18:07",
    image:
      "https://images.pexels.com/photos/2253842/pexels-photo-2253842.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2",
  },
  {
    title: "Community Events",
    description:
      "Writers in his vineyard: A purposeful way to writing for the Lord",
    location: "Foursquare gospel church",
    date: "31 July, 2021",
    time: "6pm - 8pm",
    startDate: "2023-07-21",
    startTime: "18:07",
    endDate: "2023-07-21",
    endTime: "18:07",
    image:
      "https://images.pexels.com/photos/3856033/pexels-photo-3856033.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2",
  },
  {
    title: "Writers Ministry",
    description: "Virtual marriage prep with Pastor Okien Peters",
    location: "Foursquare gospel church",
    date: "31 July, 2021",
    time: "6pm - 8pm",
    startDate: "2023-07-21",
    startTime: "18:07",
    endDate: "2023-07-21",
    endTime: "18:07",
    image:
      "https://images.pexels.com/photos/1766604/pexels-photo-1766604.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2",
  },
  {
    title: "Community Events",
    description:
      "Writers in his vineyard: A purposeful way to writing for the Lord",
    location: "Foursquare gospel church",
    date: "31 July, 2021",
    time: "6pm - 8pm",
    startDate: "2023-07-21",
    startTime: "18:07",
    endDate: "2023-07-21",
    endTime: "18:07",
    image:
      "https://images.pexels.com/photos/3856033/pexels-photo-3856033.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2",
  },
  {
    title: "Food Distribution Events",
    description: "Provisions Ministry food and diaper distribution.",
    location: "Foursquare gospel church",
    date: "31 July, 2021",
    time: "6pm - 8pm",
    startDate: "2023-07-21",
    startTime: "18:07",
    endDate: "2023-07-21",
    endTime: "18:07",
    image:
      "https://images.pexels.com/photos/6646989/pexels-photo-6646989.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2",
  },
];
