import { useState } from "react";
import Swal from "sweetalert2";

const Form = () => {
  const [contact, setContact] = useState({
    name: "",
    email: "",
    subject: "",
    honeypot: "", // if any value received in this field, form submission will be ignored.
    message: "",
    replyTo: "churchoffice@foursquarevgc.org", // this will set replyTo of email to email address entered in the form
    accessKey: "9b4f7d5b-8975-43af-879d-e45e1f25592e", // get your access key from https://www.staticforms.xyz
  });

  const handleChange = (e: any) =>
    setContact({ ...contact, [e.target.name]: e.target.value });

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    try {
      const res = await fetch("https://api.staticforms.xyz/submit", {
        method: "POST",
        body: JSON.stringify(contact),
        headers: { "Content-Type": "application/json" },
      });

      const json = await res.json();

      if (json.success) {
        Swal.fire({
          title: "Form Successfully Submitted!",
          text: "Thank you for reaching out to us.",
          icon: "success",
        });
        setContact({
          name: "",
          email: "",
          subject: "",
          honeypot: "", // if any value received in this field, form submission will be ignored.
          message: "",
          replyTo: "churchoffice@foursquarevgc.org", // this will set replyTo of email to email address entered in the form
          accessKey: "9b4f7d5b-8975-43af-879d-e45e1f25592e", // get your access key from https://www.staticforms.xyz
        });
      } else {
        Swal.fire({
          title: "An error occurred!",
          text: json.message,
          icon: "error",
          confirmButtonText: "Try again",
        });
      }
    } catch (e) {
      console.log("An error occurred", e);
      Swal.fire({
        title: "An error occurred!",
        text: "Please try submitting the form again",
        icon: "error",
        confirmButtonText: "Try again",
      });
    }
  };
  return (
    <div>
      <form
        action="https://api.staticforms.xyz/submit"
        method="post"
        onSubmit={handleSubmit}
        id="contactForm"
        name="contactForm"
        className="contactForm"
      >
        <div className="row">
          <div className="col-md-6">
            <div className="form-group">
              <label className="label" htmlFor="name">
                Full Name
              </label>
              <input
                type="text"
                className="form-control"
                name="name"
                onChange={handleChange}
                id="name"
                placeholder="Name"
                required
              />
            </div>
          </div>
          <div className="col-md-6">
            <div className="form-group">
              <label className="label" htmlFor="email">
                Email Address
              </label>
              <input
                type="email"
                className="form-control"
                name="email"
                onChange={handleChange}
                id="email"
                placeholder="Email"
                required
              />
            </div>
          </div>
          <div className="col-md-12">
            <div className="form-group">
              <label className="label" htmlFor="subject">
                Subject
              </label>
              <input
                type="text"
                className="form-control"
                name="subject"
                onChange={handleChange}
                id="subject"
                placeholder="Subject"
                required
              />
            </div>
          </div>
          <div className="col-md-12">
            <div className="form-group">
              <label className="label" htmlFor="#">
                Message
              </label>
              <textarea
                name="message"
                className="form-control"
                id="message"
                cols={30}
                onChange={handleChange}
                rows={4}
                placeholder="Message"
                required
              ></textarea>
            </div>
          </div>
          <div className="col-md-12">
            <div className="form-group">
              <button className="btn btn-primary" type="submit">
                Submit
              </button>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};

export default Form;
