import EventCountdownTimer from "components/EventCountdownTimer";
import GalleryView from "components/Gallery";
import { Link } from "react-router-dom";
import "screens/Ministries/styles.scss";
import { JoinMinistry } from "..";

const MultimediaDepartment = () => {
  return (
    <div className="ministries">
      <section
        className="hero-wrap"
        style={{
          backgroundImage: `url('${require("../../../assets/gallery/Media Team/DSC_0897.JPG")}')`,
          backgroundPositionY: "bottom",
        }}
      >
        <div className="overlay"></div>
        <div className="container">
          <div className="row no-gutters slider-text justify-content-centers align-items-center">
            <div className="col-md-8 text-left">
              <p data-aos="fade-up" className="breadcrumbs mb-2">
                <span className="mr-2">
                  <Link to="/ministries">
                    Ministries <i className="fa fa-chevron-right"></i>
                  </Link>
                </span>{" "}
                <span>
                  Multimedia <i className="fa fa-chevron-right"></i>
                </span>
              </p>
              <h1 data-aos="fade-up" className="mb-0 bread">
                Multimedia Ministry
              </h1>
            </div>
          </div>
        </div>
        <EventCountdownTimer />
      </section>

      <section className="ftco-section ministry-description">
        <div className="container">
          <div className="row"></div>
          <div className="row">
            <div className="col-md-6">
              <div className="heading-section mb-4">
                <h2>About Ministry</h2>
              </div>
              <p>
                The Multimedia Ministry at Foursquare Gospel Church VGC, we
                believe in the power of technology to enhance and amplify the
                message of the gospel. Our ministry is dedicated to leveraging
                multimedia tools to create meaningful and immersive worship
                experiences that connect hearts to the transformative love of
                Christ.
              </p>
              <p>
                Through a dynamic blend of audio, video, graphics, and
                technology, we strive to make every worship service, event, and
                communication a powerful encounter with the presence of God.
                Whether you join us in person or virtually, our Multimedia
                Ministry is committed to providing seamless and engaging content
                that reaches hearts and minds with the timeless message of hope
                and salvation.
              </p>
              <p>
                At Foursquare Gospel Church VGC, the Multimedia Ministry is more
                than technology—it's a conduit for worship, connection, and the
                proclamation of God's Word in a visually and audibly impactful
                way. Welcome to a space where technology meets spirituality, and
                together, we strive to make a lasting impact for the Kingdom.
              </p>{" "}
              <hr />
            </div>
            <div className="col-md-5 offset-md-1">
              <JoinMinistry
                info={{
                  skills:
                    "Digital skills, Attention to detail, Recording & Editing, Technical skills, Patience, Communication and Enthusiasm.",
                  social_media: [],
                  contact: {
                    people: [
                      {
                        name: "Pst Alex Adebayo",
                        email: "media@foursquarevgc.org",
                      },
                    ],
                    note: null,
                  },
                }}
              />
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default MultimediaDepartment;
