import EventCountdownTimer from "components/EventCountdownTimer";
import GalleryView from "components/Gallery";
import { Link } from "react-router-dom";
import "screens/Ministries/styles.scss";
import { JoinMinistry } from "..";

const YouthsMinistry = () => {
  return (
    <div className="ministries">
      <section
        className="hero-wrap"
        style={{
          backgroundImage: `url('${require("../../../assets/gallery/Youths/compressed/9.webp")}')`,
          backgroundPositionY: "center",
        }}
      >
        <div className="overlay"></div>
        <div className="container">
          <div className="row no-gutters slider-text justify-content-centers align-items-center">
            <div className="col-md-8 text-left">
              <p data-aos="fade-up" className="breadcrumbs mb-2">
                <span className="mr-2">
                  <Link to="/ministries">
                    Ministries <i className="fa fa-chevron-right"></i>
                  </Link>
                </span>{" "}
                <span>
                  Youth Church <i className="fa fa-chevron-right"></i>
                </span>
              </p>
              <h1 data-aos="fade-up" className="mb-0 bread">
                Youth Church
              </h1>
              <p data-aos="fade-up" className="sub-text">
                Empowering young hearts to shine brightly in faith, purpose, and
                unity
              </p>
            </div>
          </div>
        </div>
        <EventCountdownTimer />
      </section>

      <section className="ftco-section ministry-description">
        <div className="container">
          <div className="row align-items-centers">
            <div className="col-md-6">
              <div className="heading-section mb-3">
                <h2>About Ministry</h2>
              </div>
              <p>
                Welcome to the vibrant online space of the Foursquare Gospel
                Church VGC's Youth Church! Our Youth Ministry is dedicated to
                nurturing young individuals, guiding them on the path to
                becoming Christlike through fellowship and discipleship.
              </p>
              <p>
                Step into the dynamic virtual realm of Foursquare Gospel Church
                VGC's Youth Church, where our vibrant Youth Ministry is on a
                mission to nurture young souls, guiding them on the
                transformative journey to becoming Christlike through fellowship
                and discipleship. In the recently concluded church year, our
                youth community witnessed an overflow of God's favor and
                mercies, celebrating breakthroughs in spiritual growth,
                academics, careers, and businesses.
              </p>
              <p>
                Despite facing challenges like changes in leadership,
                relocations, and the tender remembrance of departed members, we
                stood united, giving glory to God. The year unfolded with six
                joyous weddings, the arrival of three precious babies, and the
                collective strength to navigate loss.
              </p>
              <p>
                Reflecting on our performance against key deliverables, the
                Youth Ministry actively pursued its mission through a tapestry
                of events. From the soul-stirring Worship at Sunset and the
                empowering Ignite Prayer Conference to the impactful March for
                Jesus and the dynamic Youth Week, each occasion aimed at
                evangelism and enhancing spiritual capacity.
              </p>
              <p>
                To cultivate a sense of community, we established two youth
                house fellowship centers, drawing an average of 14 enthusiastic
                youths every Sunday. The collaboration between our Young &
                Musical team and the Adult Choir in events like The Passion
                Worship and Christmas Worship Concert showcased unity across
                generations.
              </p>
              <p>
                Emphasizing inclusivity, we welcomed eight youth worshippers as
                official members of the church. Our efforts to retain visitors
                in youth events and services yielded positive results, with
                thirteen youths choosing to stay and grow with us, surpassing
                the previous year's figures. Some embarked on new journeys, with
                two joining Wellspring Church, and one assuming a leadership
                role in our youth church.
              </p>
              <p>
                Our commitment extends beyond geographical boundaries, as we
                maintained a strong connection with our diaspora community,
                involving them in prayer meetings and fostering a sense of
                togetherness.
              </p>
              <p>
                While the annual youth gala night took a backseat due to the
                bustling activities, we wrapped up the year with an
                unforgettable end-of-year hangout at VGC Park.
              </p>
              <p>
                Join us in celebrating a year of growth, fellowship, and divine
                connections as we look forward to the exciting adventures that
                await in the upcoming seasons. Welcome to the Youth Church
                family at Foursquare Gospel Church VGC!
              </p>
            </div>
            <div className="col-md-5 offset-md-1">
              <JoinMinistry
                info={{
                  skills: "",
                  social_media: [
                    {
                      icon: <i className="fa fa-twitter" />,
                      link: "/",
                    },
                    {
                      icon: <i className="fa fa-instagram" />,
                      link: "/",
                    },
                    {
                      icon: <i className="fa fa-link" />,
                      link: "/",
                    },
                  ],
                  contact: {
                    people: [
                      {
                        name: "Okien Peters",
                        email: null,
                      },
                      {
                        name: "IK Esefo",
                        email: null,
                      },
                    ],
                    note: (
                      <p className="sc-fkmfBh jJpdDo">
                        Reach out to the Youth administrative team by email on{" "}
                        <a href="mailto:youthchurch@foursquarevgc.org">
                          youthchurch@foursquarevgc.org
                        </a>{" "}
                        or call{" "}
                        <a href="tel: +2348071556176">+234 807 155 6176</a>
                      </p>
                    ),
                  },
                }}
              />
            </div>
          </div>
        </div>
      </section>

      <GalleryView
        title="Youth Pictures"
        folder="Youths"
        numberOfPictures={12}
        imageFormat="webp"
      />
    </div>
  );
};

export default YouthsMinistry;
