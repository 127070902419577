import Form from "components/Forms";
import "./styles.scss";

const Contact = () => {
  return (
    <div className="contact">
      <section
        className="hero-wrap"
      >
        <div className="overlay"></div>
        <div className="container">
          <div className="row no-gutters slider-text justify-content-center align-items-center">
            <div className="col-md-9 pt-5">
              <h1 className="mb-0 bread">Get in touch with us</h1>
              <p className="sub-text">We would love to connect with you</p>
            </div>
          </div>
        </div>
      </section>

      <section className="ftco-section main-contact">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-md-12">
              <div className="wrapper">
                <div className="row mb-5">
                  <div className="col-md-3">
                    <div className="dbox w-100 text-center">
                      <div className="icon icon-red d-flex align-items-center justify-content-center">
                        <span className="fa fa-map-marker"></span>
                      </div>
                      <div className="text">
                        <p>
                          <span>Address:</span> Plot PC, Road 8B (Business Section), Victoria Garden City(VGC), Lekki Pennisular, Lagos, Nigeria
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="dbox w-100 text-center">
                      <div className="icon icon-yellow d-flex align-items-center justify-content-center">
                        <span className="fa fa-phone"></span>
                      </div>
                      <div className="text">
                        <p>
                          <span>Phone:</span>{" "}
                          <a href="tel://2348082479604">+234-808-2479-604</a> (Counselling) <br />
                          <a href="tel://23417317138">+234-1-7317138</a> (Church Office)
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="dbox w-100 text-center">
                      <div className="icon icon-blue d-flex align-items-center justify-content-center">
                        <span className="fa fa-paper-plane"></span>
                      </div>
                      <div className="text">
                        <p>
                          <span>Email:</span>{" "}
                          <a href="mailto:churchoffice@foursquarevgc.org">
                            churchoffice@foursquarevgc.org
                          </a>
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="dbox w-100 text-center">
                      <div className="icon icon-purple d-flex align-items-center justify-content-center">
                        <span className="fa fa-whatsapp"></span>
                      </div>
                      <div className="text">
                        <p>
                          <span>Whatsapp</span> <a href={`https://wa.me/${2347064003124}`}>+234 706 400 3124</a>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row justify-content-center">
                  <div className="col-md-7">
                    <div className="contact-wrap w-100 p-md-5 p-4">
                      <h3 className="mb-4">Contact Us</h3>
                      <Form />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Contact;
