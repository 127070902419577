import EventCountdownTimer from "components/EventCountdownTimer";
import LiteYouTubeEmbed from "react-lite-youtube-embed";
import "react-lite-youtube-embed/dist/LiteYouTubeEmbed.css";
import { Swiper, SwiperSlide } from "swiper/react";
// import required modules
import { Pagination, Navigation, Autoplay } from "swiper";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "./styles.scss";

const About = () => {
  const pagination = {
    clickable: true,
    renderBullet: function (index: number, className: any) {
      return '<span class="' + className + '">' + (index + 1) + "</span>";
    },
  };
  return (
    <div className="about">
      <section className="hero-wrap">
        <div className="overlay"></div>
        <div className="container">
          <div className="row no-gutters slider-text justify-content-center align-items-center pb-4">
            <div className="col-md-7 ftco-animated">
              <h1 data-aos="fade-up" className="mb-0 bread">
                Who we are
              </h1>
              <p data-aos="fade-up" className="sub-text">
                We believe in Jesus Christ, the Son of God and the Trinity. At
                FourSquare Gospel, we are ready to follow Christ
              </p>
            </div>
          </div>
        </div>
        <EventCountdownTimer />
      </section>

      <section className="ftco-section symbols">
        <div className="container">
          <div className="row justify-content-center align-items-center">
            <div className="col-md-6">
              <div className="side-text">
                <h3 data-aos="fade-up">
                  The name: What does “Foursquare” mean?
                </h3>
                <p data-aos="fade-up">
                  “Foursquare” is a biblical term used for the tabernacle in the
                  Book of Exodus, of the Temple of the Lord in{" "}
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href="https://www.biblegateway.com/passage/?search=Ezekiel%2040%3A47&version=KJV"
                  >
                    <span>Ezekiel 40:47</span>
                  </a>
                  , and of Heaven, as described in the book of the Revelation.
                </p>
                <p data-aos="fade-up">
                  The term “Foursquare Gospel” was given in the inspiration of
                  revival our founder, <b>Aimee Semple McPherson</b>, during an
                  evangelistic campaign in Oakland, California, in 1922.
                </p>
                <p data-aos="fade-up">
                  It represents that which is equally balanced on all sides,
                  established, and enduring. Such confidence in the power of the
                  Gospel is also expressed by the verse,{" "}
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href="https://www.biblegateway.com/passage/?search=Hebrews+13%3A8&version=KJV"
                  >
                    <span>Hebrews 13:8</span>
                  </a>
                  , displayed in Foursquare churches proclaiming, “Jesus Christ
                  the Same, Yesterday and Today and Forever.”
                </p>
                <a
                  href="https://bit.ly/3gOCYb7"
                  rel="noreferrer"
                  target="_blank"
                  data-aos="fade-up"
                >
                  <button className="btn btn-main">Become a member</button>
                </a>
              </div>
            </div>
            <div className="col-md-5 offset-md-1">
              <div className="row">
                {symbols.map(
                  ({ image, scriptureRef, className, subheading }, index) => (
                    <div className="col-md-6 d-flex" key={index}>
                      <div
                        className={`symbol-item ${className}`}
                        data-aos="fade-up"
                      >
                        <img
                          src={image}
                          className="img-fluid"
                          width={50}
                          alt="ac"
                        />
                        <div className="text">
                          <span className="subheading">{subheading}</span>
                          <p>{scriptureRef}</p>
                        </div>
                      </div>
                    </div>
                  )
                )}
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="ftco-counter video-bg">
        <div className="container">
         <div className="row no-gutters justify-content-center pb-5 mb-2">
            <div className="col-md-8 heading-section text-center ftco-animated">
              <span className="subheading">
                Get to know us
              </span>
              <h2>Foursquare VGC Success Story</h2>
              <p>The good Lord laid it upon the heart of a few brethren in the Headquarter Church, Yaba, to start thinking, meeting and praying on the vision to have a place to worship in VGC.</p>
            </div>
          </div>
          <div className="row justify-content-center">
            <div className="col-md-7">
              <LiteYouTubeEmbed id={"i5PPq9m0-rY"} title={"title"} />
            </div>
          </div>
        </div>
      </section>

      <section className="ftco-counter bg-primarys tenets">
        <div className="container">
          <div className="row no-gutters justify-content-center pb-5 mb-2">
            <div className="col-md-7 heading-section text-center ftco-animated">
              <span className="subheading" data-aos="fade-up">
                OUR 22 TENETS
              </span>
              <h2 data-aos="fade-up">What we Believe</h2>
            </div>
          </div>
          <div className="row justify-content-center align-items-center">
            <Swiper
              slidesPerView={1}
              spaceBetween={0}
              freeMode={true}
              loop={true}
              autoplay={{
                delay: 5000,
                disableOnInteraction: false,
              }}
              grabCursor={true}
              autoHeight={true}
              pagination={pagination}
              breakpoints={{
                768: {
                  slidesPerView: 3,
                  spaceBetween: 30,
                },
              }}
              modules={[Pagination, Navigation, Autoplay]}
              className="mySwiper"
            >
              {whatWeBelieve.map(({ title, description }, index) => (
                <SwiperSlide className="col-md-4s d-flexs" key={index}>
                  <div className="tenets-item" data-aos="fade-up">
                    <h1>#{index + 1}.</h1>
                    <div>
                      <h5 className="font-weight-bold">{title}</h5>
                      <p>{description}</p>
                    </div>
                  </div>
                </SwiperSlide>
              ))}
            </Swiper>
          </div>
        </div>
      </section>

      {/* <section className="story">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-md-12 offset-md-1s text-justify">
              <div className="heading-section text-center">
                <h2 className="mb-4" data-aos="fade-up">
                  Our Church's Story
                </h2>
              </div>
            </div>
          </div>
          <div className="row images-section">
            <div className="col-md-6 text-left">
              <div className="img-1">
                <img
                  src={require("assets/images/compressed/8.webp")}
                  alt="pastor"
                  data-aos="fade-up-right"
                  className="img-fluid"
                />
              </div>
            </div>
            <div className="col-md-6 text-left">
              <div className="img-2" data-aos="fade-up-right">
                <img
                  src={require("../../assets/images/compressed/6.webp")}
                  alt="pastor"
                  data-aos="fade-up-left"
                  className="img-fluid"
                />
              </div>
            </div>
          </div>
          <div className="row justify-content-center">
            <div className="col-md-10 offset-md-1s text-justify">
              <p data-aos="fade-in">
                In response to the leading of the Lord, a group of devoted
                brethren from Foursquare Gospel Church National Headquarters,
                Yaba, were stirred in their hearts to contemplate, gather, and
                pray about establishing a place of worship in VGC (Victoria
                Garden City). This divine vision was born in the hearts of
                Brother John Obaro, Pastor Peter Ameadaji, Brother Jackson
                Ubeku, and Sister Biodun Ogunkanmi.
              </p>
              <p data-aos="fade-in">
                The inaugural service took place on the 7th of October 2001,
                held at "The Corridor" within Chrisland School, VGC. The initial
                gathering witnessed the presence of four families, comprising a
                total of eighteen enthusiastic worshippers. These families were
                the Obaros, Ameadajis, Ogunkanmis, and Utahs.
              </p>
              <p data-aos="fade-in">
                Under the leadership of the first serving Resident Pastor,
                Pastor IK Utah, the maiden service marked the establishment of
                the very first place of worship in the entire VGC community. The
                subsequent service symbolized the beginning of the church's
                growth, as additional families, namely the Amusans and Okunowos,
                joined, raising the attendance to twenty-six individuals.
              </p>
              <p data-aos="fade-in">
                Within the first three months, despite encountering various
                challenges, the church witnessed a steady increase in numbers.
                The faithful members faced obstacles directly, and when those
                attempts failed, the authorities of the Christian school were
                also approached and threatened. Nevertheless, the congregation
                remained resilient and took solace in the encouraging words of
                Romans 8:31b, "If God be for us, who can be against us."
              </p>
              <p data-aos="fade-in">
                Amidst the opposition, the Lord continued to build His church.
                Recognizing the importance of providing appropriate teaching for
                children, a children's ministry was initiated early on. As the
                number of worshippers grew, the members experienced blessings in
                various aspects of their lives. In gratitude for God's favour,
                the congregation faithfully gave tithes, offerings, and
                contributed towards land acquisition.
              </p>
              <p data-aos="fade-in">
                By the second anniversary of the church, the first parcel of
                land was fully paid for, despite the significant amount relative
                to the number of members. The testimony was clear: it is indeed
                a blessing to contribute to the work of the Lord. Although the
                church initially occupied a rented space in VGC, the management
                ultimatum from Chrisland School prompted the relocation to
                Servtrust Premises along the Lekki-Epe Expressway.
              </p>
              <p data-aos="fade-in">
                To the glory of God, the church eventually returned to VGC and
                acquired property built solely for His glory. More recently, the
                church expanded its influence by acquiring a third plot of land.
                As of now, the congregation consists of approximately 350
                worshippers, including the participation of teenagers and a
                vibrant children's church.
              </p>
              <p className="mb-4" data-aos="fade-in">
                In alignment with the Great Commission, the church has taken
                another significant step forward by planting churches in
                Jesutedo (Sangotedo) and Awoyaya, further extending the reach of
                God's love and message. With a rich history of faith,
                perseverance, and unwavering commitment, the Foursquare VGC
                community continues to impact lives and faithfully serve their
                city and beyond.
              </p>
              <p className="mt-4" data-aos="fade-in">
                <a
                  href="/https://m.youtube.com/@FoursquareChurchVGCLagos/streams"
                  rel="noreferrer"
                  target="_blank"
                >
                  <i className="fa-solid fa-video mr-2"></i> (See our journey so
                  far)
                </a>
              </p>
            </div>
          </div>
        </div>
      </section> */}

      <section className="ftco-section pastors-section">
        <div className="container">
          <div className="row justify-content-center pb-5 mb-3">
            <div className="col-md-7 heading-section text-center ftco-animated">
              <span data-aos="fade-up" className="subheading">
                Meet our leaders
              </span>
              <h2 data-aos="fade-up">Church Leadership</h2>
            </div>
          </div>
          <div className="row">
            {pastors.map(({ name, image }, index) => (
              <div className="col-md-6 col-lg-3" key={index}>
                <div className="staff" data-aos="fade-in">
                  <div
                    className="img"
                    style={{ backgroundImage: `url('${image}')` }}
                  ></div>
                  <div className="text text-1">
                    <h3>{name}</h3>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>
      {/* <section className="ftco-section pastors-section council-section">
        <div className="container">
          <div className="row justify-content-center pb-5 mb-3">
            <div className="col-md-7 heading-section text-center ftco-animated">
              <span data-aos="fade-up" className="subheading">
                Council Members
              </span>
              <h2 data-aos="fade-up">Meet our Council Members</h2>
            </div>
          </div>
          <div className="row">
            {councilMembers.map(({ name, image, position }, index) => (
              <div className="col-md-6 col-lg-3" key={index}>
                <div className="staff" data-aos="fade-in">
                  <div
                    className="img"
                    style={{ backgroundImage: `url('${image}')` }}
                  ></div>
                  <div className="text text-1">
                    <h3>{name}</h3>
                    <span>{position}</span>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section> */}
    </div>
  );
};

export default About;

const whatWeBelieve = [
  {
    title: "The Holy Scriptures",
    description: "We believe the Bible is God-inspired (II Timothy 3:16, 17).",
  },
  {
    title: "The Eternal Godhead",
    description:
      "We believe that God is triune: Father, Son, and Holy Spirit (II Corinthians 13:14).",
  },
  {
    title: "The Fall of Man",
    description:
      "We believe that man was created in the image of God, but by voluntary disobedience fell from perfection (Romans 5:12, 19).",
  },
  {
    title: "The Plan of Redemption",
    description:
      "We believe that while we were yet sinners Christ died for us, signing the pardon of all who believe in Him (John 3:16; Romans 5:8).",
  },
  {
    title: "Salvation Through Grace",
    description:
      "We believe that we have no righteousness and must come to God pleading the righteousness of Christ (Ephesians 2:8, 9).",
  },
  {
    title: "Repentance & Acceptance",
    description:
      "We believe that upon sincere repentance, and a whole-hearted acceptance of Christ, we are justified before God (Luke 24:46, 47; II Timothy 2:24-26; I John 1:9).",
  },
  {
    title: "The New Birth",
    description:
      "We believe that the change which takes place in the heart and life at conversion is a very real one (II Corinthians 5:17; Galatians 2:20).",
  },
  {
    title: "Daily Christian Life",
    description:
      "We believe that it is the will of God that we be sanctified daily, growing constantly in the faith (Hebrews 6:1).",
  },
  {
    title: "Baptism & The Lord's Supper",
    description:
      "We believe that baptism by immersion is an outward sign of an inward work (Matthew 28:19, 20). We believe in the commemoration of the Lord’s Supper by the symbolical use of the bread and juice of the vine (I Corinthians 11:24, 25).",
  },
  {
    title: "Baptism of The Holy Spirit",
    description:
      "We believe that the baptism of the Holy Spirit is to endue the believer with power; and that His incoming is after the same manner as in Bible days (Acts 2:4).",
  },
  {
    title: "The Spirit-Filled Life",
    description:
      "We believe that it is the will of God that we walk in the Spirit daily (Ephesians 4:30-32).",
  },
  {
    title: "The Gifts & Fruit of The Holy Spirit",
    description:
      "We believe that the Holy Spirit has spiritual gifts for the Christian; and that believers should show spiritual fruit as evidence of a Spirit-filled life (I Corinthians 12:1-11; Galatians 5:22, 23).",
  },
  {
    title: "Moderation",
    description:
      "We believe that the experience and daily walk of the believer should never lead him into extremes of fanaticisms (Philippians 4:5; James 5:19, 20).",
  },
  {
    title: "Divine Healing",
    description:
      "We believe that divine healing is the power of Christ to heal the sick in answer to the prayer of faith (James 5:14-16).",
  },
  {
    title: "The Second Coming of Christ",
    description:
      "We believe that the second coming of Christ is personal and imminent (I Thessalonians 4:16, 17).",
  },
  {
    title: "Church Relationship",
    description:
      "We believe it is our sacred duty to identify ourselves with the visible church of Christ (Acts l6:5; Hebrews 10:24, 25).",
  },
  {
    title: "Civil Government",
    description:
      "We believe that rulers should be upheld at all times except in things opposed to the will of God (Romans 13:1-5).",
  },
  {
    title: "Judgement",
    description:
      "We believe that all shall stand some day before the judgement seat of God; and there receive eternal life or death (Romans 2:1-16; II Corinthians 5:10).",
  },
  {
    title: "Heaven",
    description:
      "We believe that heaven is the glorious eternal home of born-again believers (John 14:1-3; Revelation 7:15-17)",
  },
  {
    title: "Hell",
    description:
      "We believe that hell is the place of eternal torment for all who reject Christ as the Saviour (Revelation 20:10-15).",
  },
  {
    title: "Evangelism",
    description:
      "We believe that soul winning is the most important responsibility of the Church (Acts 1:8; 8:4-8).",
  },
  {
    title: "Tithes & Offerings",
    description:
      "We believe that the method ordained of God for the support and spread of His cause is by giving of tithes and free-will offerings. (Malachi 3:10; II Corinthians 9:6-15).",
  },
];

const pastors = [
  {
    name: "Rev. Peter Ameadaji",
    image: require("../../assets/images/pastors/compressed/5.webp"),
  },
  {
    name: "Pastor Osarenti Ameadaji",
    image: require("../../assets/images/pastors/compressed/10.webp"),
  },
  {
    name: "Rev. Joseph Aliyu",
    image: require("../../assets/images/pastors/compressed/2.webp"),
  },
  {
    name: "Rev. Jonathan Odega",
    image: require("../../assets/images/pastors/compressed/3.webp"),
  },
  {
    name: "Pastor Okien Peters",
    image: require("../../assets/images/pastors/compressed/1.webp"),
  },
  {
    name: "Pastor Olasukanmi Olowo",
    image: require("../../assets/images/pastors/compressed/7.webp"),
  },
  {
    name: "Pastor Alex Adebayo",
    image: require("../../assets/images/pastors/compressed/6.webp"),
  },
  {
    name: "Pastor Bernice Aliyu",
    image: require("../../assets/images/pastors/Bernice Aliyu.png"),
  },
  {
    name: "Pastor Kemi Peters",
    image: require("../../assets/images/pastors/Kemi Peters.PNG"),
  },
];

const symbols = [
  {
    title: "The Cross",
    subheading: "The Saviour",
    scriptureText:
      "But He was wounded for our transgressions, He was bruised for our iniquities; the chastisement for our peace was upon Him...",
    scriptureRef: "(Isaiah 53:5)",
    image: require("../../assets/icons/flogo-cross.png"),
    className: "bg-red",
  },
  {
    title: "The Cup",
    subheading: "The Healer",
    scriptureText: "He Himself took our infirmities and bore our sicknesses...",
    scriptureRef: "(Matthew 8:17)",
    image: require("../../assets/icons/flogo-cup.png"),
    className: "bg-blue",
  },
  {
    title: "The Dove",
    subheading: "The Baptizer with the Holy Ghost",
    scriptureText:
      "For John truly baptized with water, but you shall be baptized with the Holy Spirit ... you shall receive power when the Holy Spirit has come upon you; and you shall be witnesses to Me in Jerusalem, and in all Judea and Samaria, and to the ends of the earth.",
    scriptureRef: "(Acts 1:5, 8)",
    image: require("../../assets/icons/flogo-bird.png"),
    className: "bg-yellow",
  },
  {
    title: "The Crown",
    subheading: "The Soon-coming King",
    scriptureText:
      "For the Lord Himself will descend from heaven with a shout... the dead in Christ will rise first. Then we who are alive and remain shall be caught up together with them in the clouds to meet the Lord in the air. And thus we shall always be with the Lord.",
    scriptureRef: "(Acts 1:5, 8)",
    image: require("../../assets/icons/flogo-crown.png"),
    className: "bg-purple",
  },
];
