import ChequeIcon from "../../assets/icons/give-money.png";
import DollarIcon from "../../assets/icons/bank.png";
import CardIcon from "../../assets/icons/laptop.png";
import ArrowDownImg from "../../assets/icons/arrow-down.svg";
import "./styles.scss";
import { useState } from "react";
import EventCountdownTimer from "components/EventCountdownTimer";

const faqs = [
  {
    Q: "Why do we give?",
    A: "God is the ultimate giver who gave the most precious gift imaginable - His Son Jesus - to die on the cross for our sins. Our lives are transformed by the gospel of Christ. When we learn to give generously, it changes us, making us more like our generous Creator",
  },
  {
    Q: "What is a tithe? What is an offering?",
    A: 'The word "tithe" literally means tenth or 10%. Tithing is giving the first ten percent of our income back to God. The Bible says that everything we have comes from Him, so tithing is actually returning to God what belongs to Him. When we tithe, we are putting God first in our lives and trusting Him to provide for all of our needs. An offering is anything we give beyond the 10%.',
  },
  {
    Q: "What does our giving support?",
    A: "It takes financial resources to accomplish the work God has called us to do. Our obedient tithes and generous offerings enable the Rock to fulfill its mission to Save, Equip, and Send. Our giving funds the daily operations of the church, fuels special ALL IN projects, and supports the many core and outreach ministries.",
  },
];

const Payments = () => {
  const [selectedFAQ, setSelectedFAQ] = useState<number | null>(0);

  return (
    <div className="payments">
      {/* <section className="hero-wrap">
        <div className="overlay"></div>
        <div className="container">
          <div className="row no-gutters slider-text justify-content-center align-items-center">
            <div className="col-md-6">
              <h1 className="mb-0 bread">Give to God</h1>
              <p className="sub-text">
                Your contribution makes a difference. At Foursquare, we believe
                in giving cheerfully as God loves a Cheerful giver
              </p>
            </div>
          </div>
        </div>
      </section> */}

      <section className="ftco-section ftco-no-pb ftco-no-pts bg-section1">
        <div className="container">
          <div className="row no-gutters justify-content-center margin-b-50">
            <div className="col-md-6 heading-section text-center ftco-animated">
              <h2 className="text-white">
                Give easily using any of the
                following methods.
              </h2>
              <p className="sub-text text-white">
                Your contribution makes a difference. At Foursquare, we believe
                in giving cheerfully as God loves a Cheerful giver
              </p>
            </div>
          </div>
          <div className="row">
            <div className="col-md-4">
              <div className="payment-item">
                <div className="text">
                  <div className="icon">
                    <img src={DollarIcon} alt="cheque" className="img-fluid" />
                  </div>
                  <h2>Give to Bank Account</h2>
                  <p>
                    Use your bank app or walk into any bank and make your
                    contributions directly to the church.
                  </p>
                  <div className="account-details">
                    <b>Account Name:</b> Foursquare Gospel Church, VGC <br />
                    <p>
                      <b>Bank #1:</b> Wema Bank <br />
                      <b>Account No:</b> 0122071922 <br />
                    </p>
                    <p>
                      <b>Bank #2:</b> First Bank <br />
                      <b>Account No:</b> 2012975305 <br />
                    </p>
                    <p>
                      <b>Bank #3:</b> Union Bank <br />
                      <b>Account No:</b> 0154154490 <br />
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="payment-item">
                <div className="text">
                  <div className="icon">
                    <img src={ChequeIcon} alt="cheque" className="img-fluid" />
                  </div>
                  <h2>Give in Person</h2>
                  <p>Send your cheque or gift to the church address <i className="fa-solid fa-map-pin ml-2"></i></p>
                  <div className="address">
                    Foursquare Gospel Church, VGC Lagos <br />
                    Plot PC, Road 8B (Business Section), <br />
                    Victoria Garden City(VGC), <br />
                    Lekki Pennisular, Lagos, Nigeria
                  </div>
                </div>
              </div>
              <div className="payment-item">
                <div className="text">
                  <div className="icon">
                    <img src={CardIcon} alt="cheque" className="img-fluid" />
                  </div>
                  <h2>Give Online</h2>
                  <p>
                    Visit the paylink website to make a contribution with your
                    debit or credit card
                  </p>
                  <a
                    href="http://www.paylink.ng/FGCVGC"
                    target="_blank"
                    className="btn btn-main"
                    rel="noreferrer"
                  >
                    <i className="fa-regular fa-credit-card mr-2"></i>
                    Donate
                  </a>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="payment-item">
                <div className="text">
                  <div className="icon">
                    <img src={DollarIcon} alt="cheque" className="img-fluid" />
                  </div>
                  <h2>Give from outside Nigeria</h2>
                  <div className="account-details">
                    <b>Account Name:</b> Foursquare Gospel Church, VGC <br />
                    <p>
                      <b>Bank #1:</b> Wema Bank <br />
                      <b>Account No:</b><br />
                      • USD: 0620075299<br />
                      • GBP: 0620075309<br />
                      • EUR: 0620085571<br />
                        
                        Swift Codes: WEMANGLA
                    </p>
                    <p>
                      <b>Bank #2:</b> First Bank <br />
                      <b>Account No:</b> <br />
                      • USD: 2014419056<br />
                      • GBP: 2014538276<br />

                      Swift Codes: FBNINGLA 
                    </p>
                    <p>
                      <b>Bank #3:</b> Union Bank <br />
                      <b>Account No:</b> 0154154490 <br />
                      Swift Codes: UBNINGLA
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="faq">
        <div className="container">
          <div className="row no-gutters justify-content-center margin-b-80">
            <div className="col-md-6 heading-section text-center ftco-animated">
              <h2>Frequently Asked Questions</h2>
            </div>
          </div>
          <div className="row justify-content-center">
            {faqs.map((faq, index) => {
              return (
                <div className="col-md-7" key={index}>
                  <div
                    className={`faq-item ${
                      selectedFAQ === index ? "border-gradient" : ""
                    }`}
                  >
                    <div
                      className="faq-content"
                      onClick={() =>
                        setSelectedFAQ(selectedFAQ === index ? null : index)
                      }
                    >
                      <p className="question">
                        <div>{faq.Q}</div>
                        <img src={ArrowDownImg} alt="caret" />
                      </p>
                      {selectedFAQ === index && (
                        <div className="content">
                          <p className="answer">{faq.A}</p>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </section>
    </div>
  );
};

export default Payments;
