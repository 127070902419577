import EventCountdownTimer from "components/EventCountdownTimer";
import GalleryView from "components/Gallery";
import { Link } from "react-router-dom";
import "screens/Ministries/styles.scss";
import { JoinMinistry } from "..";

const TeenagersMinistry = () => {
  return (
    <div className="ministries">
      <section
        className="hero-wrap"
        style={{
          backgroundImage: `url('${require("../../../assets/gallery/Teenagers/compressed/1.webp")}')`
        }}
      >
        <div className="overlay"></div>
        <div className="container">
          <div className="row no-gutters slider-text justify-content-left align-items-center">
            <div className="col-md-8 text-left">
              <p data-aos="fade-up" className="breadcrumbs mb-2">
                <span className="mr-2">
                  <Link to="/ministries">
                    Ministries <i className="fa fa-chevron-right"></i>
                  </Link>
                </span>{" "}
                <span>
                  Teenagers Ministry <i className="fa fa-chevron-right"></i>
                </span>
              </p>
              <h1 data-aos="fade-up" className="mb-0 bread">
                Teenagers Ministry
              </h1>
              <p data-aos="fade-up" className="sub-text">
                To minister Christ to the Teenagers until Christ is formed in
                them and to help them reach our to their network of friends for
                Christ.
              </p>
            </div>
          </div>
        </div>
        <EventCountdownTimer />
      </section>

      <section className="ftco-section ministry-description">
        <div className="container">
          <div className="row"></div>
          <div className="row align-items-centers">
            <div className="col-md-6">
              <div className="heading-section mb-4">
                <h2>About Ministry</h2>
              </div>
              <p>
                Our goal in the Teens Ministry, with 14 members: 3 youths, 11
                adults, is to minister to the Teens during Sunday Worship
                Service and Sunday School. Breaking down God's Word and the
                various monthly themes to the Teens in a way they can understand
                and relate to.
              </p>
              <h3>How we achieve our objectives</h3>
              <ul className="sc-gIvpjk dVRMhB"><li>Sunday Worship Service</li><li>Sunday School</li><li>Teens Hangout</li><li>Teens Camp</li><li>Timothy Summit</li><li>Group Discussions</li><li>Movie Sundays</li></ul>
            </div>
            <div className="col-md-5 offset-md-1">
            <JoinMinistry
                info={{
                  skills: "A passion for Teens; A passion to teach and mentor young lives; Ability to connect and relate with the younger generation.",
                  social_media: [
                    {
                      icon: <i className="fa fa-twitter" />,
                      link: "https://twitter.com/TeensVgc",
                    },
                    {
                      icon: <i className="fa fa-envelope" />,
                      link: "mailto:4sqvgc@gmail.com",
                    }
                  ],
                  contact: {
                    people: [
                      {
                        name: "Odera Okonkwo",
                        email: "ohderah@gmail.com"
                      }
                    ],
                    note: (
                      <p className="sc-fkmfBh jJpdDo">
                        First the person who must have a passion to minister to Teenagers can meet the Head of Ministry and due to the peculiar nature of working with young ones, be ready to go through a process of background checks and referrals.
                      </p>
                    ),
                  },
                }}
              />
            </div>
          </div>
        </div>
      </section>

      <GalleryView
        title="Teenagers Pictures"
        folder="Teenagers"
        numberOfPictures={8}
        imageFormat="webp"
      />
    </div>
  );
};

export default TeenagersMinistry;
