import About from 'screens/About'
import Contact from 'screens/Contact'
import Events from 'screens/Events'
import Ministries from 'screens/Ministries'
import HallOfMentorshipDepartment from 'screens/Ministries/departments/HallOfMentorship'
import MandEDepartment from 'screens/Ministries/departments/MandE'
import MultimediaDepartment from 'screens/Ministries/departments/Multimedia'
import PrayerDepartment from 'screens/Ministries/departments/Prayer'
import TheComfortCentreDepartment from 'screens/Ministries/departments/TheComfortCentre'
import TimCorpsDepartment from 'screens/Ministries/departments/TimCorps'
import ChildrenMinistry from 'screens/Ministries/pages/Children'
import FamilyMinistry from 'screens/Ministries/pages/Family'
import MenMinistry from 'screens/Ministries/pages/Men'
import TeenagersMinistry from 'screens/Ministries/pages/Teenagers'
import WomenMinistry from 'screens/Ministries/pages/Women'
import YouthsMinistry from 'screens/Ministries/pages/Youths'
import Payments from 'screens/Payments'
import Resources from 'screens/Resources'
import Sermons from 'screens/Sermons'
import Home from './screens/Home'

const publicRoutes = [
  {
    path: '/',
    element: <Home />,
    title: '',
  },
  {
    path: '/about',
    element: <About />,
    title: '',
  },
  {
    path: '/ministries',
    element: <Ministries />,
    title: '',
  },
  {
    path: '/women-ministry',
    element: <WomenMinistry />,
    title: '',
  },
  {
    path: '/men-ministry',
    element: <MenMinistry />,
    title: '',
  },
  {
    path: '/children-ministry',
    element: <ChildrenMinistry />,
    title: '',
  },
  {
    path: '/teenagers-ministry',
    element: <TeenagersMinistry />,
    title: '',
  },
  {
    path: '/youth-ministry',
    element: <YouthsMinistry />,
    title: '',
  },
  {
    path: '/family-ministry',
    element: <FamilyMinistry />,
    title: '',
  },
  {
    path: '/mission-and-evangelism',
    element: <MandEDepartment />,
    title: '',
  },
  {
    path: '/multimedia',
    element: <MultimediaDepartment />,
    title: '',
  },
  {
    path: '/prayers',
    element: <PrayerDepartment />,
    title: '',
  },
  {
    path: '/tim-corps',
    element: <TimCorpsDepartment />,
    title: '',
  },
  {
    path: '/hall-of-mentorship',
    element: <HallOfMentorshipDepartment />,
    title: '',
  },
  {
    path: '/the-comfort-centre',
    element: <TheComfortCentreDepartment />,
    title: '',
  },
  {
    path: '/sermons',
    element: <Sermons />,
    title: '',
  },
  {
    path: '/contact',
    element: <Contact />,
    title: '',
  },
  {
    path: '/events',
    element: <Events />,
    title: '',
  },
  {
    path: '/give',
    element: <Payments />,
    title: '',
  },
  {
    path: '/resources',
    element: <Resources />,
    title: '',
  },
]

export { publicRoutes }
