import EventCountdownTimer from "components/EventCountdownTimer";
import JoinMinistryForm from "components/Forms/JoinMinistryForm";
import { useState } from "react";
import { Link } from "react-router-dom";
import "./styles.scss";

const Ministries = () => {
  const [volunteerModalVisibility, setVolunteerModalVisibility] =
    useState(false);
  return (
    <div className="ministries">
      <section className="hero-wrap" style={{backgroundPositionY: "center"}}>
        <div className="overlay"></div>
        <div className="container">
          <div className="row no-gutters slider-text justify-content-center align-items-center">
            <div className="col-md-8 ftco-animated">
              <h1 data-aos="fade-up" className="mb-0 bread">
                Join a group to impact lives as Christ did on earth
              </h1>
            </div>
          </div>
        </div>
        <EventCountdownTimer />
      </section>

      <section className="ftco-section ministries-list">
        <div className="container">
          {ministries
            .filter((item) => item.title !== "Family Life")
            .map(({ title, pageDescription, image, link }, index) => (
              <div
                className={`row justify-content-start align-items-center ministry ${
                  index % 2 !== 0 ? "flex-row-reverse" : ""
                }`}
                key={index}
              >
                <div data-aos="fade-up" className="col-md-6 image">
                  <img src={image} alt="ministry" className="img-fluid" />
                </div>
                <div className="col-md-6">
                  <div className="text" data-aos="fade-up">
                    <h2>{title}</h2>
                    {pageDescription.split("\n").map((item, i) => (
                      <p key={i}>{item}</p>
                    ))}
                    <Link to={`/${link}`}>
                      <button className="btn btn-main">Learn more</button>
                    </Link>
                  </div>
                </div>
              </div>
            ))}
        </div>
      </section>

      <section className="ftco-section departments">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-md-6">
              <div className="heading-section text-center">
                <span data-aos="fade-up" className="subheading">
                  Explore Other Ministries
                </span>
                <h2 data-aos="fade-up">
                  Work for the Lord, be a special resource in many other ways
                </h2>
              </div>
            </div>
          </div>
          <div className="row">
            {departments.slice(0,6).map(({ title, description, image, link }: any, index) => {
              return (
                <div className="col-md-4 d-flex" key={index}>
                  <div className="department-item" data-aos="fade-up">
                    <div className="content">
                      <img src={image} alt={title} className="img-fluid" />
                      <h6>{title}</h6>
                      <p>{description}</p>
                      <Link to={link}>
                        <button className="btn-main btn-block">Learn more</button>
                      </Link>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
          <div className="row justify-content-center" style={{marginTop: '60px'}}>
            <div className="col-md-6">
              <div className="heading-section text-center">
                <span data-aos="fade-up" className="subheading">
                  Serve in other capacities
                </span>
                <h2 data-aos="fade-up">
                  Other departments
                </h2>
              </div>
            </div>
          </div>
          <div className="row">
            {departments.slice(6,17).map(({ title, description, image }, index) => {
              return (
                <div className="col-md-4 d-flex" key={index}>
                  <div className="department-item" data-aos="fade-up">
                    <div className="content">
                      <img src={image} alt={title} className="img-fluid" />
                      <h6>{title}</h6>
                      <p>{description}</p>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
          <div className="col-md-12 text-center mt-4">
            <button
              className="btn btn-main"
              onClick={() => setVolunteerModalVisibility(true)}
            >
              Become a volunteer
            </button>
          </div>
        </div>
      </section>
      <JoinMinistryForm
        buttonColor="black"
        isVisible={volunteerModalVisibility}
        closeModal={() => setVolunteerModalVisibility(false)}
      />
    </div>
  );
};

export default Ministries;

export const ministries = [
  {
    title: "Children's Ministry",
    link: "children-ministry",
    shortDescription:
      "We raise children to have a genuine encounter with the Lord Jesus.",
    pageDescription:
      "We believe that teaching children to love God because they are the leaders of tomorrow and we seek parents and guardians to partner with us",
    description:
      "Foursquare Kids is the children’s ministry of Foursquare Church. Our awesome team of trained volunteers work hard to ensure that while you’re loving church, your kids will be loving church too! \n We think that church should be fun for people at any age, fun activities, skilled volunteers and small group time so that children can experience for themselves who God is, how He sees them and how much He loves them.",
    image: require("../../assets/gallery/Children/compressed/17.webp"),
    img: require("../../assets/gallery/Children/compressed/17.webp"),
  },
  {
    title: "Teenager's Ministry",
    link: "teenagers-ministry",
    shortDescription:
      "We nurture teenagers to maturity through fellowship, teachings and discovering their God- given talents and purpose.",
    pageDescription:
      "This ministry exists for spiritual growth of teens for ages 13- 19 years and recognizing that youth and young adults play a vital role in the life of the Church",
    description:
      "This Department caters to young believers between the ages of 13-19 years of age, where they learn about developing their personal relationship with Christ and becoming His disciples. \n We learn by teaching the Word of God in a way that they can relate to and have activities that engage them periodically such as Timothy Summit, Teens Hangouts and Teens Home and Life which takes place every Sunday evening. It would be a pleasure to have you worship God with us and become part of our family.",
    image: require("../../assets/gallery/Teenagers/compressed/1.webp"),
    img: require("../../assets/gallery/Teenagers/compressed/1.webp"),
  },
  {
    title: "Youth Church",
    link: "youth-ministry",
    shortDescription:
      "We are christocentric champions armed with the mandate to revolutionize our generation.",
    pageDescription:
      "Youth Ministry exists to provide each & every young person an opportunity to grow personally & spiritually. While exploring ideas, values, and faith within the context of a supportive community of peers and adults.",
    description:
      "OUR VISION IS TO INSPIRE YOUTHS TO DREAM BIG. \n We are a community of youth who are crazy enough to believe that we can change the world – and we know that whatever grade we’re in this year, God has big plans for us.",
      image: require("../../assets/gallery/Youths/compressed/9.webp"),
    img: require("../../assets/gallery/Youths/compressed/9.webp"),
  },
  {
    title: "Women's Ministry",
    link: "women-ministry",
    shortDescription: "",
    pageDescription:
      "It is dedicated to affirming, equipping, and mobilizing women and also provides a way for women to be more connected to the body of Christ through the Tuesday weekly prayer meeting and other church activities.",
    description:
      "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Incidunt quas voluptate nam voluptatem animi possimus perferendis tenetur explicabo? Id itaque est delectus ducimus ab voluptatum iusto doloremque asperiores quaerat eum.",
    image: require("../../assets/gallery/Women/compressed/23.webp"),
    img: require("../../assets/gallery/Women/compressed/23.webp"),
  },
  {
    title: "Men's Ministry",
    link: "men-ministry",
    shortDescription: "",
    pageDescription:
      "The Council of Foursquare Men (CFM) exists as a body of men of 18 years and above together to support the church financially and with a mission of “preparing and equipping every man for a fruitful and effective personal ministry in the home, church, community and at work.",
    description:
      "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Incidunt quas voluptate nam voluptatem animi possimus perferendis tenetur explicabo? Id itaque est delectus ducimus ab voluptatum iusto doloremque asperiores quaerat eum.",
    image: require("../../assets/images/men.jpg"),
    img: require("../../assets/images/men.jpg"),
  },
];

export const departments = [
  {
    title: "MISSION AND EVANGELISM: (M&E)",
    description:
      "This department exists to equip the local church for effective mission and evangelism, support pastors and evangelists and missionaries.",
      image: require("../../assets/images/evang.jpg"),
    link: "/mission-and-evangelism"
  },
  {
    title: "PRAYER MINISTRY",
    description:
      "This Ministry exists to save, equip and send Prayer Warriors to present the praises and petitions of the church and community before the Lord. Every member is encouraged to join",
      image: require("../../assets/images/prayer.jpg"),
    link: "/prayers"
  },
  {
    title: "HALL OF MENTORSHIP PROGRAMME",
    description:
      "We initiate and guide mentor-mentee relationships with the goal of ensuring an all round progress and total wellbeing of mentees on our program.",
      image: require("../../assets/images/mentor.jpeg"),
    link: "/hall-of-mentorship"
  },
  {
    title: "MULTIMEDIA",
    description:
      "This ministry provides technical support required during worship services, records various events, sermons which are available on DVD and CD.",
    image: require("../../assets/gallery/Media Team/DSC_0890.JPG"),
    link: "/multimedia"
  },
  {
    title: "TIMCORPS PROGRAMME",
    description:
      "We are focused on transforming the lives of 1000 young people (aged 13-25) living in communities around the church.",
    image: require("../../assets/gallery/OtherDepartments/TimCorps.jpg"),
    link: "/tim-corps"
  },
  {
    title: "THE COMFORT CENTRE",
    description:
      "We lend a listening ear and equip individuals with tools to manage emotional and psychological stress/trauma.",
    image: require("../../assets/gallery/Welness Center/EZD_5051.JPG"),
    link: "/the-comfort-centre"
  },
  {
    title: "USHERING MINISTRY",
    description:
      "Welcomes, encourage, and support people before, during, after the each church services",
    image: require("../../assets/gallery/Ushering/DSC_9185.JPG"),
  },
  {
    title: "ADMINISTRATIVE AND LOGISTICS",
    description:
      "Exists to encourage, track, and report attendance to the Church Office, and to Membership Caring and Retention Group.",
      image: require("../../assets/images/admin.jpeg"),
  },
  {
    title: "FOLLOW UP MINISTRY",
    description:
      "Follow–up ministry exists to provide the church with a workable process that will equip for follow-ups after the first visit to the church, assimilate, and incorporate those who make a decision for Christ (Salvation) into the life and ministry of the church.",
      image: require("../../assets/images/follow.jpeg"),
  },
  {
    title: "WELFARE AND HOSPITALITY",
    description:
      "Exists to provide for the less privilege in the church and community",
      image: require("../../assets/images/welfare.jpeg"),
  },
  {
    title: "HOME AND LIFE MINISTRY (HLM)",
    description:
      "House fellowships are small groups that meet at various homes of members",
      image: require("../../assets/images/hlm.jpeg"),
  },
  {
    title: "COUPLES FELLOWSHIP AND SPECIALIZED COUNSELLING",
    description:
      "To help couples grow in your marriage, teach them God’s design for your marriage, equip with practical skills for building their marriage on purpose",
      image: require("../../assets/images/counsel.jpeg"),
  },
  {
    title: "EVENT MANAGEMENT",
    description:
      "It is administrative departments of the church that handles events and makes planning easy, example of such are Easter program, Christmas carols etc",
      image: require("../../assets/gallery/TestimonyNight/compressed/4.webp"),
  },
  {
    title: "BUILDING AND CONSTRUCTION",
    description:
      "Exists for building and any addition to a church sanctuary building matters.",
      image: require("../../assets/images/building.jpeg"),
  },
  {
    title: "MUSIC MINISTRY",
    description:
      "This ministry exists to facilitate worship, fellowship, encouragement and outreach through a balanced program of music. It also inspires, encourages, trains, and directs those whom God has called into the music ministry with a spirit of service.",
    image: require("../../assets/gallery/OtherDepartments/music_ministry.JPG")
  },
  {
    title: "SECURITY MINISTRY",
    description:
      "Security exists to provide a safe and secure environment for member and visitors in many ways",
      image: require("../../assets/images/building.jpeg"),
  },
  {
    title: "CHRISTIAN EDUCATION",
    description:
      "Christian Education Ministry exists to provide, develop and promote programs that equip individuals for discipleship. There are three areas of focus namely: spirituality, service and education.",
      image: require("../../assets/images/education.jpeg"),
  },
];

export const JoinMinistry = ({ info }: any) => {
  const {
    skills,
    social_media,
    contact: { note, people },
  } = info;
  const [modalVisibility, setModalVisibility] = useState(false);
  return (
    <div className="join-ministry">
      <h5 className="sc-gGGFjW gAApEQ">Join Ministry</h5>
      <p className="label-text">Required skills</p>
      <p className="">{skills || "None required to join"}</p>
      {/* <div className="border-top-down">
        <div className="ant-row">
          <div className="ant-col ant-col-24 ant-col-md-12 ant-col-lg-12">
            <div className="sc-jcsPjo bUEpDn">
              <p className="label-text">Social Media</p>
              <div className="col-md-6 p-0">
                <div className="social-media">
                  {social_media.length === 0
                    ? "None"
                    : social_media.map(({ icon, link }: any) => (
                        <a href={link} target="_blank" rel="noreferrer">
                          {icon}
                        </a>
                      ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> */}
      <div className="contact-info">
        <p className="label-text">Contact Information</p>
        <div className="row">
          {people.map(({ name, email }: any) => (
            <div className="col-md-6">
              <div className="sc-QxirK fWxumN">
                <p className="sc-fkmfBh jJpdDo mb-0">{name}</p>
                <a href={`mailto:${email}`} className="sc-iuhXDa dBEucF">
                  {email}
                </a>
              </div>
            </div>
          ))}
          <div className="col-md-12 mt-4">{note}</div>
        </div>
      </div>
      <div className="sc-iJCRrE bojiJp">
        <button
          type="button"
          className="btn btn-main mt-4"
          onClick={() => setModalVisibility(true)}
        >
          Join Ministry
        </button>
      </div>
      <JoinMinistryForm
        isVisible={modalVisibility}
        closeModal={() => setModalVisibility(false)}
      />
    </div>
  );
};
